import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {SGP} from "./sgp-results-react";
import Typography from "@mui/material/Typography";
import {Stack, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import moment from "moment-timezone";
import DayTask from "./DayTask";
import ResultTable from "./ResultTable";
import CompetitionHeader from "./CompetitionHeader";

const EventResults = ({editionId}) => {
    const [comp, setComp] = React.useState()
    const [dayIndex, setDayIndex] = React.useState()
    const [day, setDay] = React.useState()
    const [hasDayData, setHasDayData] = React.useState(false)

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            const comp = await (
                await fetch(SGP.resultsUrl+"/c/sgp/rest/comp/"+editionId)
            ).json();

            comp.i[comp.j]=comp.d

            setComp(comp)
            setDay(comp.d)
            setHasDayData(true)

            if ((comp.d.r.u>=1 && comp.j===comp.i.length-1) || comp.i[comp.i.length-1].y===3) {
                //last day of comp is official so show event classification
                setDayIndex(comp.i.length)
            }
            else if (comp.z.t) {
                setDayIndex(comp.j)

                //SGP.createAutoloadWidget();
                //SGP.enableAutoload();
            }
            else if (!comp.d.v && comp.j===0) {
                //no results yet for comp so show schedule
               setDayIndex(-1)
            }
        };

        dataFetch();

    }, []);

    useEffect(() => {
        if (comp && dayIndex!==undefined && dayIndex !==-1 && dayIndex!==comp.i.length) {
            if (!comp.i[dayIndex].r) {
                //we need to fetch data for this day
                setHasDayData(false)
                const dayDataFetch = async () => {
                    const day = await (
                        await fetch(SGP.resultsUrl + "/c/sgp/rest/day/" + editionId + "/" + comp.i[dayIndex].i)
                    ).json();

                    let c = comp
                    c.i[dayIndex] = day
                    setComp(c)
                    setDay(day)
                    setHasDayData(true)
                };

                dayDataFetch()
            }
            else {
                setDay(comp.i[dayIndex])
                setHasDayData(true)
            }
        }


    },[dayIndex])

    const handleDayChange = (evt, value) => {
        setDayIndex(value)
    }

    const daySelector = (comp,dayIndex) => {
        let hasValidDays=false;

        return (
            <Box sx={{}}>
                <Tabs
                    value={dayIndex}
                    onChange={handleDayChange}
                    aria-label="Day selector"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab
                        value={-1}
                        key={'schedule'}
                        label={
                            <Stack>
                                <Typography variant="caption" sx={{}}>
                                    &nbsp;
                                </Typography>
                                <Typography variant="title">
                                    Schedule
                                </Typography>
                        </Stack>
                        }
                        sx={{
                            textTransform:'none',
                            minWidth:0,
                        }}
                    />
                    {
                        comp.i.map((day,i) =>{
                            if (day.v && day.y===1) hasValidDays=true
                            return (
                                <Tab label={
                                    <Stack>
                                        <Typography variant="caption" sx={{}}>
                                            {moment(day.d).format('ddd D/M')}
                                        </Typography>
                                        <Typography variant="title"  sx={{}}>
                                            {day.l}
                                        </Typography>
                                    </Stack>

                                }
                                     value={i}
                                     key={i}
                                     disabled={!(day.v)}
                                     sx={{
                                         textTransform:'none',
                                         minWidth:0,
                                     }}
                                />
                            )
                        })
                    }
                    <Tab
                        value={comp.i.length}
                        label={
                            <Stack>
                                <Typography variant="caption" sx={{}}>
                                    &nbsp;
                                </Typography>
                                <Typography variant="title">
                                    Total
                                </Typography>
                            </Stack>
                    }
                        key={'total'}
                        sx={{
                            textTransform:'none',
                            minWidth:0,
                        }}
                    />
                </Tabs>
            </Box>
        )

    }


    return (
        <Box my={2}>
            {
                comp &&
                <>
                    {comp.c.u!==40 && daySelector(comp,dayIndex)}
                    <CompetitionHeader comp={comp} dayIndex={dayIndex}/>
                    {comp.c.u===40 &&
                        <Box py={10} my={2} sx={{bgcolor:'#f0f0f0'}} align={'center'}>
                            <Typography variant={'body1'}>NO RESULTS YET</Typography>
                        </Box>
                    }
                    {comp.c.u!==40 && dayIndex!==-1 && dayIndex!==comp.i.length &&
                        <>
                            {
                                hasDayData &&
                                <>
                                    <DayTask day={day}/>
                                    <ResultTable comp={comp} day={day} dayIndex={dayIndex}/>
                                </>
                            }
                        </>
                    }
                    {comp.c.u!==40 && (dayIndex===-1 || dayIndex===comp.i.length) &&
                        <ResultTable comp={comp} day={day} dayIndex={dayIndex}/>
                    }
                </>
            }
        </Box>
    );
};

export default EventResults;