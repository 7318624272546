function $id(id) {
    return document.getElementById(id);
}



export const CC={
    g:{},
    ui:{}
};


export const CCI={
    Util: {
        toHmsDurationString: function (millis, zeroHours) {
            if (zeroHours === undefined) zeroHours = '-';
            if (millis == 0) return zeroHours;
            var hr = Math.floor(millis / 3600000);
            var mn = Math.floor((millis - hr * 3600000) / 60000);
            var sec = Math.round((millis - hr * 3600000 - mn * 60000) / 1000);
            if (mn < 10) mn = '0' + mn;
            if (sec < 10) sec = '0' + sec;
            return hr + ":" + mn + ":" + sec;
        },
        toDeltaDurationString: function(millis, zeroHours) {
            if (zeroHours===undefined) zeroHours='-';
            if (millis==0) return zeroHours;
            var hr=Math.floor(millis/3600000);
            var mn=Math.floor((millis-hr*3600000)/60000);
            var sec=Math.round((millis-hr*3600000-mn*60000)/1000);
            if (mn<10) mn='0'+mn;
            if (sec<10) sec='0'+sec;
            return "+"+(hr>0 ? hr+":" :  "")+mn+":"+sec;
        }
    }
};

export const SGP={
    version:'1.0',
    resultsUrl:"https://www.crosscountry.aero",
    flagsUrl:"https://www.crosscountry.aero/etc/img/flags/",
    etcUrl:"/scripts/sgpscore/images/",
    calcPlacingsMap:function(comp) {
        //Use totals for each day but the last one
        //Create a map for each pilot containing placings and number of occurences
        var days=comp.i;
        var pilots=comp.p;
        var totals=comp.t.r;
        var lastDayScores=days[comp.j].r.s;
        var lastDayId=days[comp.j].i;

        var map={};

        for (let i=0;i<days.length;i++){
            let day=days[i];
            if (day.i===lastDayId) break;
            if (day.y!==1) continue;

            let ranks=totals[day.i];
            if (!ranks) continue;

            Object.keys(ranks).map( k => {
                const v = ranks[k]

                let pilotMap=map[k];

                if (!pilotMap) {
                    pilotMap={};
                    map[k]=pilotMap;
                }

                let cnt=pilotMap[v];
                if (cnt===undefined) cnt=0;
                cnt++;
                pilotMap[v]=cnt;
            })
        }

        for (let i=0;i<lastDayScores.length;i++){
            let sc=lastDayScores[i];
            let k=sc.h;
            let v=sc.r;

            let pilotMap=map[k];

            if (!pilotMap) {
                pilotMap={};
                map[k]=pilotMap;
            }

            let cnt=pilotMap[v];
            if (cnt===undefined) cnt=0;
            cnt++;
            pilotMap[v]=cnt;
        }

        return map;
    },
};

SGP.pilots={
    links:{}
};

SGP.seriesLabels={
    0:'VI',
    6:'VI',
    7:'VII',
    8:'VIII',
    9:'IX',
    10:'X',
    11:'XI',
    12:'XII',
    13:'XII',
    14:'XIV',
    15:'XV',
};

SGP.countries={
    AD:'AND',
    AF:'AFG',
    AE:'ARE',
    AG:'ATG',
    AI:'AIA',
    AL:'ALB',
    AM:'ARM',
    AO:'AGO',
    AQ:'ATA',
    AR:'ARG',
    AS:'ASM',
    AT:'AUT',
    AU:'AUS',
    AW:'ABW',
    AX:'ALA',
    AZ:'AZE',
    BA:'BIH',
    BB:'BRB',
    BD:'BGD',
    BE:'BEL',
    BF:'BFA',
    BG:'BGR',
    BH:'BHR',
    BI:'BDI',
    BJ:'BEN',
    BL:'BLM',
    BM:'BMU',
    BN:'BRN',
    BO:'BOL',
    BQ:'BES',
    BR:'BRA',
    BS:'BHS',
    BT:'BTN',
    BV:'BVT',
    BW:'BWA',
    BY:'BLR',
    BZ:'BLZ',
    CA:'CAN',
    CC:'CCK',
    CD:'COD',
    CF:'CAF',
    CG:'COG',
    CH:'CHE',
    CI:'CIV',
    CK:'COK',
    CL:'CHL',
    CM:'CMR',
    CN:'CHN',
    CO:'COL',
    CR:'CRI',
    CU:'CUB',
    CV:'CPV',
    CW:'CUW',
    CX:'CXR',
    CY:'CYP',
    CZ:'CZE',
    DE:'GER',
    DJ:'DJI',
    DK:'DNK',
    DM:'DMA',
    DO:'DOM',
    DZ:'DZA',
    EC:'ECU',
    EE:'EST',
    EG:'EGY',
    EH:'ESH',
    ER:'ERI',
    ES:'ESP',
    ET:'ETH',
    FI:'FIN',
    FJ:'FJI',
    FK:'FLK',
    FM:'FSM',
    FO:'FRO',
    FR:'FRA',
    GA:'GAB',
    GB:'GBR',
    GD:'GRD',
    GE:'GEO',
    GF:'GUF',
    GG:'GGY',
    GH:'GHA',
    GI:'GIB',
    GL:'GRL',
    GM:'GMB',
    GN:'GIN',
    GP:'GLP',
    GQ:'GNQ',
    GR:'GRC',
    GS:'SGS',
    GT:'GTM',
    GU:'GUM',
    GW:'GNB',
    GY:'GUY',
    HK:'HKG',
    HM:'HMD',
    HN:'HND',
    HR:'HRV',
    HT:'HTI',
    HU:'HUN',
    ID:'IDN',
    IE:'IRL',
    IL:'ISR',
    IM:'IMN',
    IN:'IND',
    IO:'IOT',
    IQ:'IRQ',
    IR:'IRN',
    IS:'ISL',
    IT:'ITA',
    JE:'JEY',
    JM:'JAM',
    JO:'JOR',
    JP:'JPN',
    KE:'KEN',
    KG:'KGZ',
    KH:'KHM',
    KI:'KIR',
    KM:'COM',
    KN:'KNA',
    KP:'PRK',
    KR:'KOR',
    KW:'KWT',
    KY:'CYM',
    KZ:'KAZ',
    LA:'LAO',
    LB:'LBN',
    LC:'LCA',
    LI:'LIE',
    LK:'LKA',
    LR:'LBR',
    LS:'LSO',
    LT:'LTU',
    LU:'LUX',
    LV:'LVA',
    LY:'LBY',
    MA:'MAR',
    MC:'MCO',
    MD:'MDA',
    ME:'MNE',
    MF:'MAF',
    MG:'MDG',
    MH:'MHL',
    MK:'MKD',
    ML:'MLI',
    MM:'MMR',
    MN:'MNG',
    MO:'MAC',
    MP:'MNP',
    MQ:'MTQ',
    MR:'MRT',
    MS:'MSR',
    MT:'MLT',
    MU:'MUS',
    MV:'MDV',
    MW:'MWI',
    MX:'MEX',
    MY:'MYS',
    MZ:'MOZ',
    NA:'NAM',
    NC:'NCL',
    NE:'NER',
    NF:'NFK',
    NG:'NGA',
    NI:'NIC',
    NL:'NLD',
    NO:'NOR',
    NP:'NPL',
    NR:'NRU',
    NU:'NIU',
    NZ:'NZL',
    OM:'OMN',
    PA:'PAN',
    PE:'PER',
    PF:'PYF',
    PG:'PNG',
    PH:'PHL',
    PK:'PAK',
    PL:'POL',
    PM:'SPM',
    PN:'PCN',
    PR:'PRI',
    PS:'PSE',
    PT:'PRT',
    PW:'PLW',
    PY:'PRY',
    QA:'QAT',
    RE:'REU',
    RO:'ROU',
    RS:'SRB',
    RU:'RUS',
    RW:'RWA',
    SA:'SAU',
    SB:'SLB',
    SC:'SYC',
    SD:'SDN',
    SE:'SWE',
    SG:'SGP',
    SH:'SHN',
    SI:'SVN',
    SJ:'SJM',
    SK:'SVK',
    SL:'SLE',
    SM:'SMR',
    SN:'SEN',
    SO:'SOM',
    SR:'SUR',
    SS:'SSD',
    ST:'STP',
    SV:'SLV',
    SX:'SXM',
    SY:'SYR',
    SZ:'SWZ',
    TC:'TCA',
    TD:'TCD',
    TF:'ATF',
    TG:'TGO',
    TH:'THA',
    TJ:'TJK',
    TK:'TKL',
    TL:'TLS',
    TM:'TKM',
    TN:'TUN',
    TO:'TON',
    TR:'TUR',
    TT:'TTO',
    TV:'TUV',
    TW:'TWN',
    TZ:'TZA',
    UA:'UKR',
    UG:'UGA',
    UM:'UMI',
    US:'USA',
    UY:'URY',
    UZ:'UZB',
    VA:'VAT',
    VC:'VCT',
    VE:'VEN',
    VG:'VGB',
    VI:'VIR',
    VN:'VNM',
    VU:'VUT',
    WF:'WLF',
    WS:'WSM',
    YE:'YEM',
    YT:'MYT',
    ZA:'ZAF',
    ZM:'ZMB',
    ZW:'ZWE'
};

SGP.getChampionshipTitle = (comp) => {
    return `FAI Sailplane Grand Prix - Series ${romanize(comp.c.s)}`
}

const romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}


