import { Pagination } from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import FlightTable from "./FlightTable"
import Box from "@mui/material/Box";

function FlightList ({data, pilotId, isOwn: isOwn}) {
    const navigate = useNavigate();

    const { content, pageable } = data

    return (
    <Box>
        <Box>
            <FlightTable flights={content}/>
        </Box>
        <Box sx={{display:'flex',justifyContent:'center',mt:'.5em'}}>
            <Pagination
                count={data.totalPages}
                page={pageable ? pageable.pageNumber + 1 : 1}
                onChange={(e,val) => handlePagination(e,val)}
            />
        </Box>
    </Box>
    );

    function handlePagination(e, val) {
        let req= {};
        if (!isOwn) req.pilotId = pilotId
        if ((val-1) > 0) req.page=val - 1;

        navigate('?'+new URLSearchParams(req))
    }

}

export default FlightList;