import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {grey} from "@mui/material/colors";
import {useGetIgcFileContentQuery, useGetIgcFileMetaQuery} from "../../slices/apiSlice";
import {Download} from "@mui/icons-material";
import {formatFileSize} from "../../lib/utils";

const IgcFileViewer = ({open, setOpen, flight}) => {
    const handleClose = () => {
        setOpen(false)
    }

    const {
        data: igcFileBody,
        isSuccess: isContentLoaded,
    } = useGetIgcFileContentQuery(flight.igcFileId)

    const {
        data: igcFileMeta,
        isSuccess: isMetaLoaded,
    } = useGetIgcFileMetaQuery(flight.igcFileId)

    const handleDownload = () => {
        const blob = new Blob([igcFileBody], { type: "text/plain" })
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = igcFileMeta.filename
        link.href = url;
        link.click();
    }

    return (
        <Dialog open={open}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleClose}
        >
            <DialogTitle component={'div'} sx={{pb:0,px:'2em'}}>
                <Box>IGC File</Box>
                {igcFileMeta &&
                    <Box sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'flex-start',
                        columnGap:'1em',
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            fontSize:'1rem',
                            alignItems:'center',
                            columnGap:'1em',
                            flexWrap:'wrap',
                            flexGrow:1,
                        }}>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Logger type</Box>
                                {igcFileMeta.recorderType}
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Manufacturer</Box>
                                {igcFileMeta.manufacturerCode}
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Serial no</Box>
                                {igcFileMeta.serialNumber}
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Hardware</Box>
                                {igcFileMeta.hardwareVersion}
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Firmware</Box>
                                {igcFileMeta.firmwareVersion}
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline'}}>
                                <Box className={'small-label'}>Size</Box>
                                {formatFileSize(igcFileMeta.filesize,true)}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="download"
                            onClick={handleDownload}
                            disabled = {!isContentLoaded}
                            size = 'small'
                            sx={{
                                mt:'-.2em',
                            }}>
                            <Download/>
                        </IconButton>
                    </Box>
                }
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{pt:'0.2em'}}>
                <Box sx={{
                    overflowY:'auto',
                    mb:1,
                    height:'70vh',
                    background: grey[200],
                }}>
                    <Box
                        contentEditable
                        suppressContentEditableWarning={true}
                        sx={{
                            whiteSpace:'pre',
                            p:'1em',
                    }}>
                        {isContentLoaded && igcFileBody}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default IgcFileViewer;


