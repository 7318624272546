import React from 'react';
import {useParams} from "react-router-dom";
import {useGetEventQuery} from "../../slices/apiSlice";
import EventResults from "./EventResults";
import Typography from "@mui/material/Typography";

const EventHome = () => {
    const { eventId } = useParams()
    const {data: event, isSuccess} = useGetEventQuery(eventId)

    return (
        <>
            {isSuccess && <EventResults editionId={event.id}/>}
        </>
    );
};

export default EventHome;