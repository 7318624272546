import React, {useEffect, useState} from "react";
import {useLoaderData} from "react-router-dom";
import AirfieldList from "../../components/AirfieldList";
import CountrySelector from "../../components/CountrySelector";
import {useGetRegionsQuery} from "../../slices/apiSlice";

const defaultCountry = 'GR'

export default function Airfields(props) {

    const res = useLoaderData();
    const { country } = res;

    const {
        data: regions,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetRegionsQuery()

    const activeCountries = isSuccess ? calculateCountryWhitelist(regions) : undefined

    return (
        <>
            {isSuccess &&
                <CountrySelector countries={activeCountries} selectedCountry={country}/>
            }
            <AirfieldList data={res} country={country}/>
        </>
    )
}

function calculateCountryWhitelist (regions) {
    let countries=[];
    regions.map((region) => countries.push(region.c))
    return Array.from(new Set(countries))
}

export async function loader({ request }) {
    const url = new URL(request.url);
    let country = url.searchParams.get("country");
    const page = url.searchParams.get("page");

    if (!country) country = defaultCountry;

    let req = {
        country: country,
    }

    if (page) req.page=page;

    const res = await fetch('http://localhost:8888/get/airfield?'+ new URLSearchParams(req))
        .then((response) => response.json())
        .then((data) => {
            data.country=country;
            return data;
        })

    return res;

}



