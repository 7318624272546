import './App.css';
import React, {useEffect, useState} from 'react';
import MenuBar from "./components/MenuBar";
import { Outlet } from "react-router-dom";
import { CountryRegionData } from 'react-country-region-selector';
import {useDispatch} from "react-redux";
import {setCountryData} from "./slices/globalsSlice";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {authenticating} from "./slices/sessionSlice";
import {useRefreshAuthMutation} from "./slices/apiSlice";


function App() {

    const dispatch = useDispatch()
    const [hasLoaded, setHasLoaded] = useState(false)

    const [refreshAuth, { isLoading, isError: hasRefreshAuthError, error, isSuccess: hasRefreshAuth }] =
        useRefreshAuthMutation()


    //TODO: we need to replace this with data from regions
    useEffect(() => {
        //initialize all data that the app assumes is always there
        //TODO: we need to evolve this for an async request
        const token = localStorage.getItem('token')
        if (token) {
            dispatch(authenticating())
            refreshAuth({token:token})
        }

        const data = CountryRegionData;
        const countryMap = {}

        data.map(c => {
            countryMap[c[1]]={name:c[0]}
        })

        dispatch(setCountryData(countryMap))

        console.log('[INFO] --- CC app initialized')
        setHasLoaded( true)
    }, []);


    return (
                <div className="App">
                    <MenuBar/>
                    {hasLoaded ?
                        <Outlet/>
                        :
                        <Box sx={{ display: 'flex', flexDirection:'column',  justifyContent:'center', alignItems:'center', height:'80vh' }}>
                            <Typography variant={'h3'} component={'h2'} sx={{marginBottom: '2rem'}}>Loading ...</Typography>
                            <CircularProgress />
                        </Box>
                    }
                </div>
    );
}

export default App;
