import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import Error from "../pages/Error";
import Flights from "../pages/Crosscountry/Flights";
import Pilots from "../pages/Crosscountry/Pilots";
import Airfields from "../pages/Crosscountry/Airfields";
import Sailplanes from "../pages/Crosscountry/Sailplanes";
import Club from "../pages/Club";
import Competition from "../pages/Competition";
import Analysis from "../pages/Analysis";
import Planning from "../pages/Planning";
import Crosscountry from "../pages/Crosscountry";
import Flight from "../pages/Flight";
import App from "../App";
import {loader as airfieldsLoader} from "../pages/Crosscountry/Airfields";
import Login from "../pages/Login"
import Terms from "../pages/Terms"
import Home from "../pages/Home";
import Logbook from "../components/Logbook";
import EventHome from "../pages/Competition/EventHome";
import CompetitionList from "../pages/Competition/CompetitionList";
import CompetitionHome from "../pages/Competition/CompetitionHome";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error/>,
        children: [
            {
                index:true,
                element:<Home/>,
            },
            {
                path:"logbook",
                element:<Logbook/>
            },
            {
                path:'view/flight/:flightId',
                element: <Flight/>,
            },
            {
                path:"community",
                element:<Crosscountry/>,
                children:[
                    {
                        path:"flights",
                        element:<Flights/>,
                    },
                    {
                        path:"pilots",
                        element:<Pilots/>,
                    },
                    {
                        path:"airfields",
                        element:<Airfields/>,
                        loader:airfieldsLoader,
                    },
                    {
                        path:"sailplanes",
                        element:<Sailplanes/>,
                    },
                ]
            },
            {
                path:"club",
                element:<Club/>
            },
            {
                path:"competition",
                element:<Competition/>,
                children:[
                    {
                        index:true,
                        element:<CompetitionHome/>
                    },
                    {
                        path: "event/:eventId/home",
                        element: <EventHome/>,
                    }
                ],
            },
            {
                path:"analysis",
                element:<Analysis/>
            },
            {
                path:"planning",
                element:<Planning/>
            },
            {
                path:"login",
                element:<Home/>
            },
            {
                path:"terms",
                element:<Terms/>
            },
        ],
    },
]);

export default router;