import React from 'react';
import moment from "moment-timezone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DayTask = ({day}) => {
    if (!day.k || day.y===3 || day.y===4) return <></>

    const taskDistance = JSON.parse(day.k.description).d;
    const taskStartTime = moment.utc(day.a).format('HH:mm')

    let desc="";
    let pts=day.k.data.g;
    for (let i=0; i<pts.length;i++) {
        desc+=(i>0 ? " - " : "")+pts[i].n;
    }

    return (
        <Box sx={{bgcolor:'#f0f0f0',textAlign:'left'}} p={1}>
            <Typography variant={'body2'}><span style={{fontWeight:700}}>Start time:</span> {taskStartTime}</Typography>
            <Typography variant={'body2'}><span style={{fontWeight:700}}>Task:</span> {taskDistance}</Typography>
            <Typography variant={'body2'} sx={{fontStyle:'italic',fontSize:'12px',mt:'4px'}}>{desc}</Typography>
        </Box>
    );
};

export default DayTask;