import React from 'react';
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import {findFlagUrlByIso2Code} from 'country-flags-svg';

const PilotInfo = ({ flight }) => {
    const pilot = flight?.pilot

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
            <PersonIcon className={'icon'} fontSize={'small'}/>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                flexWrap:'wrap',
                rowGap: '0.3em',
            }}>
                <Box sx={{
                    whiteSpace: 'nowrap',
                    pr: '.4em',
                }}>
                    {pilot?.firstname} {pilot?.lastname}
                </Box>
                <Box sx={{
                    whiteSpace: 'nowrap',
                }}>
                    <img src={findFlagUrlByIso2Code(pilot?.nationality)} style={{height:'.7em'}}/>
                </Box>
            </Box>
        </Box>
    );
};

export default PilotInfo;