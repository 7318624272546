import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Card, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from 'moment-timezone';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

const romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}


const CompetitionCard = ({comp}) => {

    const navigate = useNavigate()

    const sgpCard = (comp, img, dates) => {

        return (
            <Card sx={{display:'flex'}} variant="outlined" onClick={() => {navigate(`/competition/event/${comp.id}/home`)}}>
                <Box sx={{bgcolor:'#113248',}}>
                    <img src={img} style={{width:'200px',objectFit:'contain'}} alt="Competition logo"/>
                    <Typography variant={'body2'} sx={{color:'#ffffff',mb:'6px',fontWeight:700,mt:'-8px'}}>
                        Series {romanize(comp.series)}
                    </Typography>
                </Box>
                <CardContent sx={{textAlign:'left',padding:'8px 18px'}}>
                    <Typography variant={'h6'}>
                        {comp.fullEditionTitle}
                    </Typography>
                    <Typography variant={'body2'}>
                        {dates}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    const genericCard = (comp, img, dates) => {
        return (
            <Card sx={{display:'flex'}} variant="outlined"onClick={() => {navigate(`/competition/event/${comp.id}/home`)}}>
                <CardMedia
                    component="img"
                    image={img}
                    sx={{width:'250px',objectFit:'contain'}}
                    alt="Competition logo"
                />
                <CardContent sx={{textAlign:'left',padding:'8px 18px'}}>
                    <Typography variant={'h6'}>
                        {comp.fullEditionTitle}
                    </Typography>
                    {comp.series > 0 &&
                        <Typography variant={'body1'} gutterBottom>
                            Series {romanize(comp.series)}
                        </Typography>
                    }
                    <Typography variant={'body2'}>
                        {dates}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    let img,dates,card

    if (moment(comp.firstDate).year() === moment(comp.lastDate).year()) {
        if (moment(comp.firstDate).month() === moment(comp.lastDate).month()) {
            dates = `${moment(comp.firstDate).format('D')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
        }
        else {
            dates = `${moment(comp.firstDate).format('D MMMM')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
        }
    }
    else {
        dates = `${moment(comp.firstDate).format('D MMMM yyyy')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
    }

    switch (comp.competitionId) {
        case 2:
            img='/etc/img/bitner-logo-banner.png'
            card = genericCard(comp, img, dates)
            break;
        case 0x2001:
            img='/etc/img/wag_logo.png'
            card = genericCard(comp, img, dates)
            break;
        case 3:
            img='/etc/img/sgp/sgp-logo.png'
            card = sgpCard(comp, img, dates)
            break;
        default:
            img='/etc/img/sgp/cc-og-logo.png'
            card = genericCard(comp, img, dates)
    }


    return (
        <Grid2 key={`ced-${comp.id}`} xs={12}>
            {card}
        </Grid2>
    )
};

export default CompetitionCard;