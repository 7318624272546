import React from 'react';
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import CompetitionCard from "./CompetitionCard";
import {useNavigate} from "react-router-dom";



const CompetitionList = ({events}) => {


    return (
        <Box sx={{flexGrow:1}}>
            <Grid2 spacing={2} container>
                {events && events.map((comp) => {
                    return (
                        <CompetitionCard key={comp.id} comp={comp}/>
                    )
                })}
            </Grid2>
        </Box>
    );
};

export default CompetitionList;