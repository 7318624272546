import L from "leaflet";


export const Util = {
    toTimeString: function (millis, allowMinSec) {
        let dte = new Date(millis);
        let hr = dte.getUTCHours();
        let mn = dte.getUTCMinutes();
        let sc = dte.getUTCSeconds();
        if (hr < 10) hr = '0' + hr;
        if (mn < 10) mn = '0' + mn;
        if (sc < 10) sc = '0' + sc;
        return (allowMinSec !== undefined && allowMinSec ? (hr > 0 ? hr + ":" : "") : hr + ":") + mn + ":" + sc;
    },

    getHeading: function (p1, p2, p3) {
        if (p1 == null || p2 == null) return 0;
        let a1 = this.calcBearing(p1, p2);
        if (p3 == null) return a1;
        let a2 = this.calcBearing(p2, p3);

        if (a1 > a2) {
            let s = a1;
            a1 = a2;
            a2 = s;
        }
        let d = a2 - a1;
        if (d <= 180.0) {
            return (d / 2.0 + a1) % 360;
        } else {
            return ((360 - d) / 2.0 + a2) % 360;
        }
    },
    calcBearing: function (p1, p2) {
        let dlat = (p2.lat - p1.lat) * Math.PI / 180;
        let dlon = (p2.lng - p1.lng) * Math.PI / 180;
        let rlat1 = (p1.lat) * Math.PI / 180;
        let rlat2 = (p2.lat) * Math.PI / 180;

        let y = Math.sin(dlon) * Math.cos(rlat2);
        let x = Math.cos(rlat1) * Math.sin(rlat2) - Math.sin(rlat1) * Math.cos(rlat2) * Math.cos(dlon);
        let b = Math.atan2(y, x) * 180 / Math.PI;
        if (b < 0.0) b = 360 + b;

        return b;
    },

    calcBisector: function (a1, a2) {
        if (a1 > a2) {
            let s = a1;
            a1 = a2;
            a2 = s;
        }
        let d = a2 - a1;
        if (d <= 180.0) {
            return (d / 2.0 + a1) % 360;
        } else {
            return ((360 - d) / 2.0 + a2) % 360;
        }
    },

    toCoords: function (latlng, sep) {
        let c = '';

        if (sep === undefined) sep = "&nbsp;";

        let lat = latlng.lat;
        let lon = latlng.lng;

        c += lat < 0 ? "S " : "N ";
        let la = Math.abs(lat);
        let ld = Math.floor(la);
        let lm = (la - ld) * 60;
        let ls = Math.round((lm - Math.floor(lm)) * 6000) / 100;
        if (ld < 10) c += "0";
        c += ld + "&deg;";
        if (lm < 10) c += ("0");
        c += Math.floor(lm) + "'";
        if (ls < 10) c += "0";
        c += ls + '"';

        c += sep;

        c += lon < 0 ? " W " : " E ";
        c += Math.abs(lon) < 100.0 ? "0" : "";
        la = Math.abs(lon);
        ld = Math.floor(la);
        lm = (la - ld) * 60;
        ls = Math.round((lm - Math.floor(lm)) * 6000) / 100;
        if (ld < 10) c += "0";
        c += ld + "&deg;";
        if (lm < 10) c += "0";
        c += (Math.floor(lm) + "'");
        if (ls < 10) c += "0";
        c += ls + '"';

        return c;
    },

    toInputCoords: function (latlng, sep) {
        let c = '';

        if (sep === undefined) sep = " ";

        let lat = latlng.lat;
        let lon = latlng.lng;

        c += lat < 0 ? "S " : "N ";
        let la = Math.abs(lat);
        let ld = Math.floor(la);
        let lm = (la - ld) * 60;
        let ls = Math.round((lm - Math.floor(lm)) * 6000) / 100;
        if (ld < 10) c += "0";
        c += ld + " ";
        if (lm < 10) c += ("0");
        c += Math.floor(lm) + " ";
        if (ls < 10) c += "0";
        c += ls + ' ';

        c += sep;

        c += lon < 0 ? "W " : "E ";
        c += Math.abs(lon) < 100.0 ? "0" : "";
        la = Math.abs(lon);
        ld = Math.floor(la);
        lm = (la - ld) * 60;
        ls = Math.round((lm - Math.floor(lm)) * 6000) / 100;
        if (ld < 10) c += "0";
        c += ld + " ";
        if (lm < 10) c += "0";
        c += (Math.floor(lm) + " ");
        if (ls < 10) c += "0";
        c += ls + ' ';

        return c;
    },

    parseCoords: function (txt) {
        txt = txt.toUpperCase();

        let west = txt.indexOf('W') != -1;
        let south = txt.indexOf('S') != -1;

        txt = txt.replace(/\'/g, " ");
        txt = txt.replace(/\"/g, " ");
        txt = txt.replace(/�/g, " ");
        txt = txt.replace(/,/g, " ");
        txt = txt.replace('N', " ");
        txt = txt.replace('E', " ");
        txt = txt.replace('S', " ");
        txt = txt.replace('W', " ");
        txt = txt.replace(/\s{2,}/g, ' ');
        txt = txt.trim();

        let p = txt.split(" ");

        let t = [];
        let sneg = false;

        for (let i = 0; i < p.length; i++) {
            if (p[i] != '+' && p[i] != '-') {
                if (sneg) {
                    t[t.length] = '-' + p[i];
                } else {
                    t[t.length] = p[i];
                }
                sneg = false;
            } else {
                if (p[i] != '-') sneg = true;
            }
        }

        let lat = Number.NaN;
        let lon = Number.NaN;

        if (t.length == 2) {
            lat = Number(t[0]);
            lon = Number(t[1]);

        } else if (t.length == 4) {
            lat = Number(t[0]) + Number(t[1]) / 60.00;
            lon = Number(t[2]) + Number(t[3]) / 60.00;
        } else if (t.length == 6) {
            lat = Number(t[0]) + Number(t[1]) / 60.00 + Number(t[2]) / 3600.00;
            lon = Number(t[3]) + Number(t[4]) / 60.00 + Number(t[5]) / 3600.00;
        }

        if (west) {
            lon = -lon;
        }
        if (south) {
            lat = -lat;
        }

        if (Math.abs(lat) > 90) lat = Number.NaN;
        if (Math.abs(lon) > 180) lon = Number.NaN;

        if (isNaN(lat) || isNaN(lon)) return undefined;

        return new L.LatLng(lat, lon);
    }
}


export class FlightTrace {
    constructor() {

    }

    readFromGMap(data) {
        //the legacy map trace payload has this format
        //function gmdi() {gmZoom=11;gmcLat=38.4252;gmcLon=22.89496;gmFixes=flightTraceArray(38.45542,23.1384,"
        // # #");}

        this.gmZoom = parseInt(this.parse(data,'gmZoom=',';'))
        this.gmcLat = parseFloat(this.parse(data,'gmcLat=',';'))
        this.gmcLon = parseFloat(this.parse(data, 'gmcLon=',';'))
        let traceData = this.parse(data, 'flightTraceArray','");}')+'");}'
        let lat = parseFloat(this.parse(traceData,'(',','))
        let lon = parseFloat(this.parse(traceData,',',','))
        let enc = this.parse(traceData,'"','");}')

        this.fixes = this.flightTraceArray(lat,lon,enc)
    }

    parse(txt,startString,endSting,startPos = 0) {
        let start=txt.indexOf(startString,startPos)+startString.length
        let end=txt.indexOf(endSting,start)
        return txt.substring(start,end)
    }

    flightTraceArray(la,lo,enc) {
        let args = this.decodeArray(enc);
        let n=args.length-1;
        let ret=[]
        let j=0;
        ret[j++]=new L.LatLng(la,lo);
        for (let i=0 ; i<n ; i=i+2)	{
            la+=args[i]/100000;
            lo+=args[i+1]/100000;
            ret[j++]=new L.LatLng(la,lo);
        }
        return ret;
    }

    decodeArray(txt) {
        let ns=[]
        let token=txt.charAt(0);
        let j=0;
        for (let i = 1; i < txt.length; i++) {
            let c=txt.charAt(i);
            if (c==' ' || c=='!') {
                ns[j++]=this.decode(token);
                token="";
            }
            token+=c;
        }
        ns[j++]=this.decode(token);
        return ns;
    }

    decode(txt) {
        let positive=txt.charAt(0)=='!' ? false: true;
        let x=0;
        for (let i = 0; i < txt.length-1; i++) {
            let c=txt.charCodeAt(i+1);
            c= c==126 ? 92 : c ;
            x+=Math.floor((c-35)*Math.pow(90, i));
        }
        return positive ? x : -x;
    }
}

export class Data4D {
    constructor (d4, options){
        this.options = Object.assign({},options)
        this._decode(d4);
    }

    _decode(data) {
        if (data == null || data.count==0) return;
        let b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let o1, o2, o3, o4, o5, o6,  h1, h2, h3, h4, bits;


        this.alt=[];
        this.time=[];
        this.enl=[];

        this.qnhOffset=data.qnhOffset;
        this.altitudeSource=data.altitudeSource;
        this.timezoneOffset=data.timezoneOffset;;
        this.enlInfo=false;
        this.maxHeight=-1000;
        this.minHeight=30000;

        let mdAlt=this.alt;
        let mdTime=this.time;
        let mdEnl=this.enl;

        let d=data.altitudeData;
        let count=data.count;
        let n=d.length/8;

        for (let i=0 ; i<n ; i++) {
            h1 = b64.indexOf(d.charAt(i*8));
            h2 = b64.indexOf(d.charAt(i*8+1));
            h3 = b64.indexOf(d.charAt(i*8+2));
            h4 = b64.indexOf(d.charAt(i*8+3));

            bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;

            o1 = bits >> 16 & 0xff;
            o2 = bits >> 8 & 0xff;
            o3 = bits & 0xff;

            h1 = b64.indexOf(d.charAt(i*8+4));
            h2 = b64.indexOf(d.charAt(i*8+5));
            h3 = b64.indexOf(d.charAt(i*8+6));
            h4 = b64.indexOf(d.charAt(i*8+7));

            bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;

            o4 = bits >> 16 & 0xff;
            o5 = bits >> 8 & 0xff;
            o6 = bits & 0xff;

            let idx=mdAlt.length;
            mdAlt[mdAlt.length]=this._maskNegative(o1 << 8 | o2);
            if (mdAlt[idx]<this.minHeight) this.minHeight=mdAlt[idx];
            if (mdAlt[idx]>this.maxHeight) this.maxHeight=mdAlt[idx];
            if (mdAlt.length<count) {
                mdAlt[mdAlt.length]=this._maskNegative(o3 << 8 | o4);
                if (mdAlt[idx+1]<this.minHeight) this.minHeight=mdAlt[idx+1];
                if (mdAlt[idx+1]>this.maxHeight) this.maxHeight=mdAlt[idx+1];
            }
            if (mdAlt.length<count) {
                mdAlt[mdAlt.length]=this._maskNegative(o5 << 8 | o6);
                if (mdAlt[idx+2]<this.minHeight) this.minHeight=mdAlt[idx+2];
                if (mdAlt[idx+2]>this.maxHeight) this.maxHeight=mdAlt[idx+2];
            }
        }

        mdTime[0]=data.timeOffset;
        let pointer=data.pointer;
        let interval=data.interval;

        for (let j=0 ; j<pointer.length;j++) {
            let a=pointer[j];
            let b=j+1==pointer.length ? mdAlt.length-1:  pointer[j+1];
            let itv=interval[j];
            for (let i=a; i<b ;i++) {
                mdTime[i+1]=mdTime[i]+itv;
            }
        }

        count=data.enlDataCount;
        if (count!=0) {

            d=data.enlData;

            let bytes=[]

            for (let i=0 ; i<d.length ; i=i+4) {
                h1 = b64.indexOf(d.charAt(i));
                h2 = b64.indexOf(d.charAt(i+1));
                h3 = b64.indexOf(d.charAt(i+2));
                h4 = b64.indexOf(d.charAt(i+3));

                bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;

                bytes[bytes.length]=bits >> 16 & 0xff;
                bytes[bytes.length]=bits >> 8 & 0xff;
                bytes[bytes.length]=bits & 0xff;
            }

            let i=0;
            let j=0;

            while (i<count) {
                let b=bytes[j++];
                if ((b & 0xc0)==0) {
                    mdEnl[i++]=(0x03 & b)-2;
                    mdEnl[i++]=((0x0c & b) >>> 2)-2;
                    mdEnl[i++]=((0x30 & b) >>> 4)-2;
                }
                else if ((b & 0xc0)==0x40) {
                    mdEnl[i++]=(0x07 & b)-4;
                    mdEnl[i++]=((0x38 & b) >>> 3)-4;
                }
                else if ((b & 0xc0)==0x80) {
                    mdEnl[i++]=(b & 0x3f) - 32;
                }
                else if ((b & 0xe0)==0xe0) {
                    let z=(b & 0x1f)*3+10;

                    for (let k=0 ; k<z ; k++) {
                        mdEnl[i++]=0;
                    }
                }
                else if ((b & 0xf0)==0xd0) {
                    let z=(b & 0x0f)*3+10;

                    for (let k=0 ; k<z ; k++) {
                        mdEnl[i++]=-1;
                    }
                }
                else if ((b & 0xf0)==0xc0) {
                    let sh=(((bytes[j++] << 8) &0xff00) | (bytes[j++] & 0xff)) & 0xffff;
                    if (sh>32767) sh=sh-65536;
                    mdEnl[i++]=sh;
                }
            }

            let offset=0;

            for (i=0 ; i<mdEnl.length; i++) {
                mdEnl[i]=mdEnl[i]+offset;
                offset=mdEnl[i];
            }

            this.enlInfo=true;
        }

    }

    _maskNegative(x) {
        return x>32767 ? x-65536 : x;
    }
}


