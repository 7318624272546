import React, {useContext, useEffect} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Flight.css';
import Divider from "@mui/material/Divider";
import FlightIcon from '@mui/icons-material/Flight';
import HeightIcon from '@mui/icons-material/Height';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import UpdateIcon from '@mui/icons-material/Update';
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {Chip, Skeleton} from "@mui/material";
import FlightContext from "./FlightContext";
import SailplaneInfo from "./SailplaneInfo";
import AirfieldInfo from "./AirfieldInfo";
import DateInfo from "./DateInfo";
import RegionInfo from "./RegionInfo";
import PerformanceInfo from "./PerformanceInfo";
import DurationInfo from "./DurationInfo";
import {Straight} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import IgcFileViewer from "./IgcFileViewer";
import PerformanceSelector from "./PerformanceSelector";
import SoaringDurationInfo from "./SoaringDurationInfo";


const FlightInfo = ({flight, performances}) => {
    const flightContext = useContext(FlightContext)
    const [performance, setPerformance ] = React.useState()
    const [bestPerformance, setBestPerformance ] = React.useState()
    const [performanceMap, setPerformanceMap] = React.useState({})
    const [ igcFilePaneOpen, setIgcFilePaneOpen ] = React.useState(false)
    let olcPerformance

    useEffect(() => {
        if (performances) {
            let bestPerf
            let perfMap ={}
            performances.map(p => {
                if (p.id == flight.bestPerformanceId) bestPerf = p
                if (p.type == 15) olcPerformance = p
                perfMap[p.id] = p
            })
            flightContext.performances = performanceMap
            setPerformanceMap(perfMap)
            setPerformance(bestPerf)
            if (bestPerf) setBestPerformance(bestPerf)
        }
    },[performances])

    const showIgcFile = () => {
        setIgcFilePaneOpen(true)
    }


    const handlePerformanceSelect = (performanceId) => {
        if (performanceId) {
            setPerformance(performanceMap[performanceId])
        }
    }


    return (
        <Paper className={'flightInfo'} sx={{display:'flex', flexDirection:'column', alignItems:'start', padding:'.8em'}}>
            <Box sx={{flex:1,display:'flex',flexDirection:'column',rowGap:'.2em'}} >
                <DateInfo flight={flight} variant={'large'}/>
                <AirfieldInfo flight={flight}/>
                <RegionInfo flight={flight}/>
                <SailplaneInfo flight={flight}/>
                <Box>&nbsp;</Box>
                <DurationInfo flight={flight} variant={'xl'}/>
            </Box>
            <Divider flexItem sx={{marginY:'.3em'}}/>
            <Box sx={{flex:1,width:'100%'}}>
                {
                    performances && performance ?

                        <PerformanceInfo flight={flight} performance={performance} variant={'xl'}/>
                :
                    !performances &&
                    <Box>
                        <Skeleton variant="text"  sx={{width:'100%'}}/>
                        <Skeleton variant="text"  sx={{width:'100%'}}/>
                        <Skeleton variant="text"  sx={{width:'100%'}}/>
                        <Skeleton variant="text"  sx={{width:'100%'}}/>
                        <Skeleton variant="text"  sx={{width:'100%'}}/>
                    </Box>
                }
            </Box>
            {performances && bestPerformance && performances.length > 0 &&
                <Box sx={{
                    display:'flex',
                    alignSelf:'stretch',
                    justifyContent:'space-between',
                }}>
                    <PerformanceSelector
                        performances={performances}
                        selectedPerformance={bestPerformance}
                        handleSelect={handlePerformanceSelect}
                    />
                    {olcPerformance && olcPerformance.id != bestPerformance.id &&
                        <Chip label={`OLC`} onClick={() => {}} />
                    }
                </Box>
            }
            <Divider flexItem sx={{marginY:'.3em'}}/>
            <Box sx={{flex:1,display:'flex',flexDirection:'column',rowGap:'.2em'}}>
                <SoaringDurationInfo flight={flight} variant={'xl'}/>
                <Box sx={{
                    display:'flex',
                    alignItems:'baseline',
                    justifyContent:'space-between',
                    fontSize:'.8em',
                }}>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <VerticalAlignTopIcon className={'icon'} fontSize={'small'}/>
                        <Tooltip title={'Maximum height'} arrow>
                            <>
                                {flight.maxHeight+flight.pressureAltOffset} m
                            </>
                        </Tooltip>
                    </Box>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <HeightIcon className={'icon'} fontSize={'small'}/>
                        <Tooltip title={'Maximum height gain'} arrow>
                            <>
                                {flight.maxHeightGain} m
                            </>
                        </Tooltip>
                    </Box>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <Straight className={'icon'} fontSize={'small'} sx={{
                            transform:  'rotate(180deg)',
                        }}/>
                        <Tooltip title={'Soaring flight height loss'} arrow>
                            <>
                                {flight.releaseFix.alt-flight.endFix.alt} m
                            </>
                        </Tooltip>
                    </Box>
                </Box>



            </Box>
            <Box sx={{
                display:'flex',
                alignSelf:'stretch',
                justifyContent:'space-between',
            }}>
                <Chip label="Declaration" onClick={() => {}} />
                <Chip label="IGC file" onClick={showIgcFile}/>
                { igcFilePaneOpen &&
                    <IgcFileViewer open={igcFilePaneOpen} setOpen={setIgcFilePaneOpen} flight={flight}/>
                }
            </Box>
        </Paper>
    )

}

export const AirfieldLabel = ({airfield}) => {
    const countries =  useSelector(state => state.globals.countries)

    return (
        <>
            <FlightIcon className={'icon'} fontSize={'small'}/>
            <Typography variant={'h3'} component={'h2'}>
                {airfield.n} ({countries[airfield.c].name})
            </Typography>
        </>
    )
}


export default FlightInfo