import React from 'react';
import Box from "@mui/material/Box";
import FlightIcon from "@mui/icons-material/Flight";
import {useSelector} from "react-redux";

const PilotInfo = ({ flight, showCountry = false }) => {
    const airfield = flight?.takeoffAirfield
    const countries =  useSelector(state => state.globals.countries)

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
            <FlightIcon className={'icon'} fontSize={'small'}/>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                flexWrap:'wrap',
                rowGap: '0.3em',
            }}>
                <Box sx={{
                    pr: '.4em',
                }}>
                    {airfield?.n}
                </Box>
                {showCountry &&
                    <Box sx={{
                        whiteSpace: 'nowrap',
                    }}>
                        ({countries[airfield?.c].name})
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default PilotInfo;