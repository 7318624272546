import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from "../slices/sessionSlice";
import globalsReducer from "../slices/globalsSlice";
import {apiSlice} from "../slices/apiSlice";


const store = configureStore({
    reducer: {
        session:sessionReducer,
        globals: globalsReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware)
})

export default store;