import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Flight.css';
import SailplaneInfo from "./SailplaneInfo";
import PilotInfo from "./PilotInfo";
import AirfieldInfo from "./AirfieldInfo";
import DateInfo from "./DateInfo";
import {Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import PerformanceInfo from "./PerformanceInfo";
import DurationInfo from "./DurationInfo";


const FlightHeader = ({flight, performance}) => {

    return (
        <Stack sx={{mb:'.3em'}}>
            <Box className={'flightHeader'} sx={{
                display:'flex',
                alignItems:'end',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                rowGap: '0.5em',
                columnGap: '2em',
            }}>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis: {md:'30%'}, display:'flex'}}>
                    <PilotInfo flight={flight}/>
                </Typography>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis:{md:'30%'},justifyContent:{md:'center'}, display:'flex'}}>
                    <DateInfo flight={flight}/>
                </Typography>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis:{md:'30%'}, justifyContent:{md:'end'}, display:'flex'}}>
                    <AirfieldInfo flight={flight} showCountry={true}/>
                </Typography>
            </Box>
            <Divider sx={{my:'.5em'}}/>
            <Box className={'flightHeader'} sx={{
                display:'flex',
                alignItems:'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                rowGap: '0.5em',
                columnGap: '2em',
            }}>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis:{md:'30%'}, display:'flex'}}>
                    <SailplaneInfo flight={flight} variant={'s'}/>
                </Typography>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis:{md:'30%'},justifyContent:{md:'center'}, display:'flex'}}>
                    <DurationInfo flight={flight} variant={'s'}/>
                </Typography>
                <Typography variant={'h3'} component={'div'} sx={{flexBasis:{md:'30%'}, justifyContent:{md:'end'}, display:'flex'}}>
                    <PerformanceInfo flight={flight} performance={performance} variant={'s'}/>
                </Typography>
            </Box>
        </Stack>
    )
}



export default FlightHeader