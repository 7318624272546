import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import {useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '.85em',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: grey[100],
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



export default function CustomizedTables(props) {

    const navigate = useNavigate()

    return (
        <TableContainer sx={{}} component={'div'}>
            <Table stickyHeader sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Sailplane</StyledTableCell>
                        <StyledTableCell>Registration</StyledTableCell>
                        <StyledTableCell>Takeoff</StyledTableCell>
                        <StyledTableCell>Land</StyledTableCell>
                        <StyledTableCell>Takeoff Airfield</StyledTableCell>
                        <StyledTableCell>Duration</StyledTableCell>
                        <StyledTableCell>Points</StyledTableCell>
                        <StyledTableCell>km</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.flights.map((row) => (
                        <StyledTableRow key={row.id} onClick={() => navigate(`/view/flight/${row.id}`)}>
                            <StyledTableCell component="th" scope="row">
                                {moment.utc(row.date).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell>{row.gliderType}</StyledTableCell>
                            <StyledTableCell>{row.gliderId}</StyledTableCell>
                            <StyledTableCell>{moment.utc(row.startTime).utcOffset(row.timezoneOffset/60000).format('HH:mm')}</StyledTableCell>
                            <StyledTableCell>{moment.utc(row.endTime).utcOffset(row.timezoneOffset/60000).format('HH:mm')}</StyledTableCell>
                            <StyledTableCell>{row.takeoffAirfield ? `${row.takeoffAirfield.n} (${row.takeoffAirfield.c})` : ' '}</StyledTableCell>
                            <StyledTableCell>{moment.utc(row.flightDuration).format('H:mm')}</StyledTableCell>
                            <StyledTableCell>{row.score}</StyledTableCell>
                            <StyledTableCell>{row.distance}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}