import React from 'react';
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {useSelector} from "react-redux";

const RegionInfo = ({ flight }) => {
    const countries =  useSelector(state => state.globals.countries)
    const airfield = flight?.takeoffAirfield

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
            <LocationOnIcon className={'icon'} fontSize={'small'}/>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                flexWrap:'wrap',
                rowGap: '0.3em',
            }}>
                <Box sx={{
                    pr: '.4em',
                }}>
                    {countries[airfield.c].name}
                </Box>
            </Box>
        </Box>
    );
};

export default RegionInfo;