import React from 'react';
import Box from "@mui/material/Box";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment-timezone";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import StartIcon from "@mui/icons-material/Start";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import Tooltip from "@mui/material/Tooltip";
import {grey} from "@mui/material/colors";

const sizes = ['xs','s','m','l','xl']

const SoaringDurationInfo = ({ flight, variant='l' }) => {

    const size = sizes.indexOf(variant)

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            rowGap:'.2em',
        }}>
            <Box sx={{
                display:'flex',
                alignItems:'baseline',
                justifyContent:'space-between',
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <TimelapseIcon className={'icon'} fontSize={'small'}/>
                    <Box sx={{marginRight:'1em'}}>
                        <Tooltip title={'Soaring duration'} arrow>
                            <>
                                {moment.utc(flight.soaringDuration).format('H:mm:ss')}
                            </>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{
                    background:grey[200],
                    padding:'0px 3px'
                }}>
                    <Box sx={{
                        display:'flex',
                        fontSize:'.7em',
                        alignItems:'flex-start',
                    }}>
                        <Box sx={{display:'flex', alignItems:'end',flexDirection:'column'}}>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <StartIcon className={'icon'} fontSize={'small'}/>
                                <Tooltip title={'Release'} arrow>
                                    <>
                                        {moment.utc(flight.releaseFix.utc).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                                    </>
                                </Tooltip>
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <Box>
                                    {flight.releaseFix.alt+flight.pressureAltOffset} m
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{display:'flex', alignItems:'end',flexDirection:'column'}}>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <KeyboardTabIcon className={'icon'} fontSize={'small'} sx={{marginLeft:'1em'}}/>
                                <Tooltip title={'Soaring end'} arrow>
                                    <>
                                        {moment.utc(flight.endFix.utc).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                                    </>
                                </Tooltip>
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <Box>
                                    {flight.endFix.alt+flight.pressureAltOffset} m
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    { false && size > 2 &&
                        <Box sx={{
                            fontSize:'.8em',
                        }}>
                            <Box sx={{display:'flex',alignItems:'baseline',justifyContent:'space-between',mt:'.5em'}}>
                                <Box className={'small-label'}>Max height</Box>
                                <Tooltip title={'Maximum height'} arrow>
                                    <>
                                        {flight.maxHeight+flight.pressureAltOffset} m
                                    </>
                                </Tooltip>
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline',justifyContent:'space-between'}}>
                                <Box className={'small-label'}>Max gain</Box>
                                <Tooltip title={'Maximum height gain'} arrow>
                                    <>
                                        {flight.maxHeightGain} m
                                    </>
                                </Tooltip>
                            </Box>
                            <Box sx={{display:'flex',alignItems:'baseline',justifyContent:'space-between'}}>
                                <Box className={'small-label'}>Height loss</Box>
                                <Tooltip title={'Soaring flight height loss'} arrow>
                                    <>
                                        {flight.releaseFix.alt-flight.endFix.alt} m
                                    </>
                                </Tooltip>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default SoaringDurationInfo;