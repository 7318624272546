import React from 'react';
import Box from "@mui/material/Box";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment-timezone";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import StartIcon from "@mui/icons-material/Start";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import Tooltip from "@mui/material/Tooltip";
import UpdateIcon from "@mui/icons-material/Update";
import {grey} from "@mui/material/colors";

const sizes = ['xs','s','m','l','xl']

const DurationInfo = ({ flight, variant='l' }) => {

    const size = sizes.indexOf(variant)

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            rowGap:'.2em',
        }}>
            <Box sx={{
                display:'flex',
                alignItems:'baseline',
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <AccessTimeIcon className={'icon'} fontSize={'small'}/>
                    <Box sx={{marginRight:'1em'}}>
                        <Tooltip title={'Flight duration'} arrow>
                            <>
                                {moment.utc(flight.flightDuration).format('H:mm:ss')}
                            </>
                        </Tooltip>
                    </Box>
                </Box>
                {size > 1 && size <3 &&
                    <Box sx={{display:'flex', fontSize:'.7em', alignItems:'center'}}>
                        <FlightTakeoffIcon className={'icon'} fontSize={'small'}/>
                        <Tooltip title={'Takeoff'} arrow>
                            <>
                                {moment.utc(flight.startTime).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                            </>
                        </Tooltip>
                        <FlightLandIcon className={'icon'} fontSize={'small'} sx={{marginLeft:'1em'}}/>
                        <Tooltip title={'Landing'} arrow>
                            <>
                                {moment.utc(flight.endTime).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                            </>
                        </Tooltip>
                    </Box>
                }
                {size >= 3 &&
                    <Box sx={{background:grey[200],padding:'0px 3px'}}>
                        <Box sx={{display:'flex', fontSize:'.7em', alignItems:'center'}}>
                            <FlightTakeoffIcon className={'icon'} fontSize={'small'}/>
                            <Tooltip title={'Takeoff'} arrow>
                                <>
                                    {moment.utc(flight.startTime).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                                </>
                            </Tooltip>
                            <FlightLandIcon className={'icon'} fontSize={'small'} sx={{marginLeft:'1em'}}/>
                            <Tooltip title={'Landing'} arrow>
                                <>
                                    {moment.utc(flight.endTime).utcOffset(flight.timezoneOffset/60000).format('HH:mm:ss')}
                                </>
                            </Tooltip>
                        </Box>
                        <Box sx={{display:'flex',alignItems:'center',fontSize:'.7em',}}>
                            <UpdateIcon className={'icon'} fontSize={'small'}/>
                            <Tooltip title={'Timezone'} arrow>
                                <>
                                    {flight.timezoneCode}
                                </>
                            </Tooltip>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default DurationInfo;