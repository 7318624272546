import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import Login from "../pages/Login";
import {AUTHENTICATED, NOT_AUTHENTICATED} from "../slices/sessionSlice";



export default function Authenticated({children, notAuthenticatedView =  <Login modal/>}){
    const session = useSelector(state => state.session)


    return (
        <>
            {
                session.status === AUTHENTICATED &&
                    children
            }
            {
                session.status === NOT_AUTHENTICATED &&
                notAuthenticatedView
            }
        </>
    )
}