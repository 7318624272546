import { createSlice } from '@reduxjs/toolkit'

const initGlobals= {
    countries:{},
    messages: {
        performanceTypes:{
            1:{name:'Duration',shortname:'Duration'},
            2:{name:'Gain of Height',shortname:'Height'},
            3:{name:'Absolute Height',shortname:'Absolute Height'},
            4:{name:'Straight Distance',shortname:'Straight Distance'},
            5:{name:'Straight Distance in Leg',shortname:'Straight Distance'},
            6:{name:'Distance to Goal',shortname:'Goal'},
            7:{name:'Distance with up to 3 Turn Points',shortname:'3TP'},
            8:{name:'Distance Out & Return',shortname:'Out & Return'},
            9:{name:'Distance FAI Triangle',shortname:'FAI Triangle'},
            10:{name:'Free Straight Distance',shortname:'Free Distance'},
            11:{name:'Free 3 Turn Point Distance',shortname:'Free 3TP'},
            12:{name:'Free Out & Return Distance',shortname:'Free Out & Return'},
            13:{name:'Free FAI Triangle Distance',shortname:'Free Triangle'},
            15:{name:'OLC distance',shortname:'OLC'},
            19:{name:'Distance Triangle',shortname:'Triangle'},
            21:{name:'Speed Out & Return',shortname:'Speed Out & Return'},
            22:{name:'Speed FAI Triangle',shortname:'Speed Triangle'},
        }
    },
}
export const globalsSlice = createSlice({
    name: 'globals',
    initialState: initGlobals,
    reducers: {
        setCountryData: (state, action) => {
            state.countries = action.payload
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        }
    }
})

export const { setCountryData, setMessages } = globalsSlice.actions

export default globalsSlice.reducer

















