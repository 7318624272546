import {Component} from "react";
import "./AirfieldList.css"
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AirfieldTable from "./AirfieldTable"

function AirfieldList (props) {
    const navigate = useNavigate();

    const { data } = props
    const { content, pageable, country } = data

    return (
    <div className="airfieldList">
        <div className="tableBody">
            <AirfieldTable airfields={content}/>
        </div>
        <div className="footer">
            <Pagination
                count={data.totalPages}
                page={pageable ? pageable.pageNumber + 1 : 1}
                onChange={(e,val) => handlePagination(e,val,country)}
            />
        </div>
    </div>
    );

    function handlePagination(e, val, country) {
        let req= {country:country};
        if ((val-1) > 0) req.page=val - 1;

        navigate('?'+new URLSearchParams(req))
    }

}

export default AirfieldList;