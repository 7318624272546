import React, {useContext} from 'react';
import {useMap} from 'react-leaflet'
import L from "leaflet";
import {useGetGMapQuery} from "../../slices/apiSlice";
import {FlightTrace} from "../../lib/cc-map";
import FlightContext from "../Flight/FlightContext";

const MapFlightRenderer = ({children}) => {
    const map = useMap()

    const flightContext = useContext(FlightContext)
    const flight = flightContext.flight

    const {
        data: gmap,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetGMapQuery(flight.id)

    const flightTrace=new FlightTrace()
    const traceLayer = new L.FeatureGroup()


    if (isSuccess) {
        initTrace(gmap)
        flightContext.flightTrace=flightTrace
        flightContext.ccmap = map.ccmap
    }

    function initTrace(traceData) {
        flightTrace.readFromGMap(traceData)
        traceLayer.addTo(map)

        let fdToBr=flight.takeoffFix ? flight.takeoffFix.brec : 0
        let fdRlBr=flight.releaseFix ? flight.releaseFix.brec : 0
        let fdEdBr=flight.endFix ? flight.endFix.brec : 0
        let fdLdBr=flight.landFix ? flight.landFix.brec : 0

        const gmFixes = flightTrace.fixes
        map.fixes=gmFixes;

        if (fdToBr>0) {
            const flightPathPolyPre = L.polyline(gmFixes.slice(0, fdToBr + 1), {color: 'grey', weight: 1, opacity: 1});
            traceLayer.addLayer(flightPathPolyPre);
        }

        if (fdLdBr>0 && fdLdBr<(gmFixes.length-1)) {
            const flightPathPolyPost=L.polyline(gmFixes.slice(fdLdBr,gmFixes.length), {color: 'grey', weight:1, opacity:1});
            traceLayer.addLayer(flightPathPolyPost);
        }

        if (fdRlBr>0) {
            const flightPathPolyLaunch=L.polyline(gmFixes.slice(fdToBr,fdRlBr+1), {color: 'green', weight:1, opacity:1});
            traceLayer.addLayer(flightPathPolyLaunch);
        }

        const flightPathPolySoaring=L.polyline(gmFixes.slice(fdRlBr, fdEdBr+1), {color: 'blue', weight:1, opacity:1});

        traceLayer.addLayer(flightPathPolySoaring);

        if (fdEdBr>0 && fdEdBr<fdLdBr) {
            const flightPathPolyEnded=L.polyline(gmFixes.slice(fdEdBr, fdLdBr+1), {color: 'red', weight:1, opacity:1});
            traceLayer.addLayer(flightPathPolyEnded);
        }
    }

    return (
        <>
            {children}
        </>
    )
    //do what we want with Leaflet map object
}

export default MapFlightRenderer
