import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {Skeleton} from "@mui/material";
import {useSelector} from "react-redux";
import FlightContext from "./FlightContext";
import {useGetFlightPerformancesQuery} from "../../slices/apiSlice";
import moment from "moment-timezone";

const sizes = ['xs','s','m','l','xl']

export const getPerformanceDescription = (performance) => {
    if (performance) {
        if (performance.type === 1) return moment.utc(performance.performance).format('H:mm:ss')
        else if (performance.type === 2) return `${performance.performance.toLocaleString()} m`
        else if (performance.type === 3) return `${performance.performance.toLocaleString()} m`
        else if (performance.type >= 4 && performance.type <=19) return `${performance.performance.toLocaleString(undefined, { minimumFractionDigits: 2 })} km`
        else if (performance.type >= 21 && performance.type <=22) return `${performance.performance.toLocaleString(undefined, { minimumFractionDigits: 2 })} km/h`
        return ''
    }
}

const PerformanceInfo = ({ flight, performance, variant='l' }) => {
    const performanceTypes =  useSelector(state => state.globals.messages.performanceTypes)
    const size = sizes.indexOf(variant)

    const description = getPerformanceDescription(performance)

    const flexDirection = size > 2 ? 'column' : 'row'
    const fontSize = size < 3 ? '.7em' : '1em'
    const ml = size < 3 ? '.5em' : 0
    const alignSelf = size > 2 ? 'stretch' : undefined
    const descriptionFontSize = size >2 ? '1.3em' : '1em'

    return (
        <Box sx={{
            display:'flex',
            alignItems:'baseline',
        }}>
            {
                performance &&
                <Box sx={{
                    display:'flex',
                    flexDirection: {flexDirection},
                    alignItems:'baseline',
                    rowGap:'.2em'
                }}>
                    <Box sx={{
                        fontSize: {fontSize},
                        mr:'.5em',
                    }}>
                        {size < 2 ? performanceTypes[performance.type].shortname : performanceTypes[performance.type].name}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems:'baseline',
                        justifyContent: 'space-between',
                        alignSelf:{alignSelf},
                    }}>
                        <Box sx={{
                            fontSize:descriptionFontSize,
                        }}>
                            {description}
                        </Box>
                        {size >= 3 && flight &&  flight.bestPerformanceId === performance.id &&
                            <Box sx={{
                                fontSize:'.8em',
                                ml:'1em',
                            }}>({flight.score} pts)</Box>
                        }
                    </Box>
                    {size > 1 && performance.type >=4 && performance.type <= 19 &&
                        <Box sx={{
                            fontSize:'.8em',
                            ml: {ml},
                        }}>
                            { size < 3 && <span>(</span>}
                            {performance.speed.toLocaleString(undefined, { minimumFractionDigits: 2 })} km/h
                            { size < 3 && <span>)</span>}
                        </Box>
                    }
                    {size > 1 && performance.type >=21 && performance.type <= 22 &&
                        <Box sx={{
                            fontSize:'.8em',
                            ml: {ml},
                        }}>
                            { size < 3 && <span>(</span>}
                            {performance.distance.toLocaleString(undefined, { minimumFractionDigits: 2 })} km
                            { size < 3 && <span>)</span>}
                        </Box>
                    }
                    {size >= 3 &&
                        <Box sx={{
                            fontSize:'.8em',
                        }}>{moment.utc(performance.duration).format('H:mm:ss')}</Box>
                    }
                </Box>
            }
        </Box>
    )
}

/*
<hr class="panelHr">
                    <div class="boxTitle"><spring:message code="flight.performance"/></div>
                    <div id="perfInfoTarget" style="display:none;"></div>
                    <div style="display:inline-block;vertical-align:top;margin-left:-4px;padding-right:10px;"><img src='/etc/img/perf${bestPerformance.type}.png'></div>
                    <div style="display:inline-block;padding-top:3px;">
                    <div><spring:message code="performance.type.${bestPerformance.type}.name"/></div>
                    <div style="padding-top:6px;padding-bottom:6px;">
     				<div class="perfNumber">
                    <c:choose>
                            <c:when test="${bestPerformance.type==1}">
                                <cc:HourFormat time="${bestPerformance.performance}" format="hms"/>
                            </c:when>
                            <c:when test="${bestPerformance.type==2}">
                                <fmt:formatNumber value="${bestPerformance.performance}" pattern="#,##0"/> m
                            </c:when>
                            <c:when test="${bestPerformance.type==3}">
                                <fmt:formatNumber value="${bestPerformance.performance}" pattern="#,##0"/> m
                            </c:when>
                            <c:when test="${bestPerformance.type>=4 && bestPerformance.type <=19}">
                                <fmt:formatNumber value="${bestPerformance.roundedPerformance}" pattern="#,##0"/> km
                            </c:when>
                            <c:when test="${bestPerformance.type>=21 && bestPerformance.type <=22}">
                                <fmt:formatNumber value="${bestPerformance.performance}" pattern="#,##0.00"/> km/h
                            </c:when>
                        </c:choose>
                    </div>
                    <div class="perfPts">${flight.score} <spring:message code="generic.pts"/></div>
                    </div>
                    <c:choose>
                        <c:when test="${bestPerformance.type>=4 && bestPerformance.type <=19}">
                            <div><fmt:formatNumber value="${bestPerformance.speed}" pattern="#,##0.00"/> km/h</div>
                            <div><cc:HourFormat time="${bestPerformance.duration}" format="hms"/></div>
                        </c:when>
                        <c:when test="${bestPerformance.type>=21 && bestPerformance.type <=22}">
                            <div><fmt:formatNumber value="${bestPerformance.distance}" pattern="#,##0.00"/> km</div>
                            <div><cc:HourFormat time="${bestPerformance.duration}" format="hms"/></div>
                        </c:when>
                    </c:choose>
                    <c:if test="${not empty olc && not empty bestPerformance && bestPerformance.type!=15}">
                        <br>
	                	<div>OLC <span style="font-size:14pt;"><fmt:formatNumber value="${olc.roundedPerformance}" pattern="#,##0"/> km</span> (<fmt:formatNumber value="${olc.speed}" pattern="#,##0.00"/> km/h)</div>
                	</c:if>
				</div>
 */

export default PerformanceInfo;