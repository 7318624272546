import React from 'react';
import {Airlines} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

const sizes = ['xs','s','m','l','xl']

const SailplaneInfo = ({ flight, variant = 'l' }) => {

    const size = sizes.indexOf(variant)

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
            <Airlines className={'icon'} fontSize={'small'}/>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                flexWrap:'wrap',
                rowGap: '0.3em',
            }}>
                <Box sx={{
                    display:'flex',
                    alignItems:'center',
                }}>
                    { size > 1 && flight?.gliderCompId &&
                        <Box sx={{
                            whiteSpace: 'nowrap',
                            borderRadius:'5px',
                            fontWeight:700,
                            border:'solid 1px #666666',
                            color:'#666666',
                            p: '0 .25em',
                            lineHeight: '1.35em',
                            fontSize:'.75em'
                        }}>
                            <Tooltip title={'Sailplane competition ID'} arrow>
                                <>
                                    {flight?.gliderCompId}
                                </>
                            </Tooltip>
                        </Box>
                    }
                    <Box sx={{
                        whiteSpace: 'nowrap',
                        mx:'.5em',
                        '&:first-of-type':{
                            ml:0,
                        },
                    }}>
                        {flight.gliderType ? flight.gliderType : 'Glider'}
                    </Box>
                </Box>
                <Box sx={{
                    display:'flex',
                    alignItems:'center'
                }}>
                    {size > 2 && flight.gliderId &&
                        <Box sx={{
                            whiteSpace: 'nowrap',
                            //borderRadius:'5px',
                            fontWeight:700,
                            background:'#888888',
                            color:'#ffffff',
                            p: '0 .3em',
                            lineHeight: '1.35em',
                            fontSize:'.75em',
                            mr:'.35em'}}>
                            <Tooltip title={'Sailplane registration'} arrow>
                                <>
                                    {flight.gliderId}
                                </>
                            </Tooltip>
                        </Box>
                    }
                    {size > 0 && flight.gliderClass &&
                        <Box sx={{
                            whiteSpace: 'nowrap',
                            color:'#666666',
                            fontSize:'.70em',
                            mr:'2em'}}>
                            <Tooltip title={'Sailplane competition class'} arrow>
                                <>
                                    {flight.gliderClass}
                                </>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default SailplaneInfo;