import React, {useContext} from 'react';
import {useMap} from 'react-leaflet'
import L from "leaflet";
import {Util} from '../../lib/cc-map'

import FlightContext from "../Flight/FlightContext";

const MapTaskRenderer = ({performanceId}) => {
    const map = useMap()

    const flightContext = useContext(FlightContext)
    const flight = flightContext.flight


    //TODO: we get data for the task from somewhere and then render
    let isSuccess = true

    if (isSuccess) {
        //draw task

        const pid =  performanceId ?? flight?.bestPerformanceId

        const p= flightContext.performances ? flightContext.performances[pid] : undefined

        //TODO:add task object to existing task layer defined in MapControls
        const taskLayer = new L.FeatureGroup()

        //TODO: there is a bug in cc that does not allow us to get ozType info
        if (p) {
            const data={
                wpt:[],
                perfType:p.type,
                wptOzType:[],
                wptData:[],
                fix:[],
            }

        }
        //map.addTask(task)

/*
        <c:forEach items="${scores}" var="s" varStatus="i">
            CC.g.taskData[${i.count-1}]=undefined;
            perfIds[${i.count-1}]=${s[0].id};
            perfValues[${i.count-1}]={v:${s[0].performance},d:${s[0].distance},s:${s[0].speed}};
            perfHScores[${i.count-1}]=${not empty s[1] ? s[1] : "0"};
            perfScores[${i.count-1}]=${not empty s[2] ? s[2] : "0"};
            pfw[${i.count-1}]=new Array();
            pff[${i.count-1}]=new Array();
            pfo[${i.count-1}]=new Array();
            pfz[${i.count-1}]=new Array();
            pft[${i.count-1}]=${s[0].type};
            <c:if test="${not empty s[0].course}">
                <c:forEach items="${s[0].course.waypoints}" var="w" varStatus="j">
                    pfw[${i.count-1}][${j.count-1}]=new L.LatLng(${w.lat},${w.lon});
                    pfo[${i.count-1}][${j.count-1}]=${w.OZType};
                    pfz[${i.count-1}][${j.count-1}]=${w.OZType==11 || w.OZType==13 || w.OZType==14 ? w.azimouth : w.OZType==16 ? w.radius : 0};
                </c:forEach>
                <c:forEach items="${s[0].course.fixes}" var="f" varStatus="j">
                    pff[${i.count-1}][${j.count-1}]=${f.brec};
                </c:forEach>
            </c:if>
        </c:forEach>

 */

    }

}

export default MapTaskRenderer


/**A task, which can represent a task geometry or an actual performance.
 * As this object has evolved over time, it has been designed to encompass a number
 * of different implementations. The different implementations are identified by the clazz property.
 *
 * Clazz:
 * 1 - Legacy performance (used for flight display)
 * 2 - Transisional performance (used for flight display)
 * 3 - Task (used in any map)
 */
export class Task {
    constructor(task, flightTrace, options) {
        this.options = Object.assign({},options)

        let t=this;

        t.flightTrace=flightTrace
        t.task=task;
        t.clazz=t.task.taskData ? 2 : t.task.data && t.task.data.g ? 3 : 1;
    }
    addToMap(layer) {
        let t=this;
        if (t.clazz==3) {
            t.drawTask(layer);
        }
        else if (t.clazz==2) {
            t.drawTransitional(layer);
        }
        else {
            t.drawLegacy(layer);
        }
    }
    drawTask(layer) {
        let t=this;
        let d=t.task;

        let g=t.task.data.g;
        let f=t.task.data.f;

        let wpts=[];

        t.bounds= g.length ? L.latLngBounds(new L.latLng(g[0].a, g[0].o),new L.latLng(g[0].a, g[0].o)): undefined;

        for (let i=0;i<g.length;i++){
            let w=g[i];
            wpts[i]=new L.latLng(w.a, w.o);
            t.bounds.extend(wpts[i]);
        }



        let taskLine=new L.Polyline(wpts,{
            color: '#000680',
            opacity: .9,
            weight: 2
        });

        layer.addLayer(taskLine);

        if (((d.type==0x1109 || d.type==0x1213 || d.type==0x1322 || d.type==0x12000 || d.type==0x13000) && wpts.length>4) ||
            (wpts.length==8 && (d.type==0x15000 || d.type==0x16000)) ||
            (wpts.length==11 && (d.type==0x18000 || d.type==0x19000))
        ) {
            let tri=new L.Polyline([wpts[1],wpts[3]],{
                color: '#000680',
                opacity: .9,
                weight: 2,
                dashArray: '5,7'
            });
            layer.addLayer(tri);
        }

        let declaredOz=[];

        for (let i=0; i<g.length; i++) {
            declaredOz[i]=true;
            let w=g[i];
            switch(w.y) {
                case 'sector':
                    layer.addLayer(
                        new Sector(wpts[i], 3000,30, t.calcOzAzimouth(i,g), {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'cylinder':
                    layer.addLayer(
                        new Cylinder(wpts[i] , w.r ? w.r : 500, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'limitedSector':
                    layer.addLayer(
                        new LimitedSector(wpts[i], w.r ? w.r : 1000, 8, t.calcOzAzimouth(i,g), {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'line':
                    layer.addLayer(
                        new Line(wpts[i] , w.r, 5,t.calcOzAzimouth(i,g), {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'fix':
                    declaredOz[i]=false;
                    layer.addLayer(
                        new L.CircleMarker(wpts[i] , {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#00ffff',
                            fillOpacity: 0.5,
                            radius:4
                        })
                    );
                    break;
                case 'area':
                    layer.addLayer(
                        new Cylinder(wpts[i] , w.r, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'ring':
                    layer.addLayer(
                        new Cylinder(wpts[i] , w.r, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
            }
        }

        if (f) {
            for (let i=0;i<f.length;i++){
                if (declaredOz[i] && f[i]!==null) {
                    layer.addLayer(new L.CircleMarker(new L.LatLng(f[i].lat, f[i].lon), {
                        color: '#000680',
                        opacity: .9,
                        weight: 1,
                        fill: true,
                        fillColor: '#00ffff',
                        fillOpacity: 0.5,
                        radius: 4
                    }));
                }
            }
        }

        for (let i=0 ; i<wpts.length; i++) {
            let marker = new L.Marker(wpts[i],{
                zIndexOffset: 20-i,
                icon: L.icon({iconUrl:'/etc/img/'+this._wpIconName(i,wpts.length)+'.png', iconSize:[19,32],iconAnchor:[0,31]})
            });
            layer.addLayer(marker);
        }

    }
    getBounds(){
        return this.bounds;
    }
    calcOzAzimouth(idx,ozs) {
        let oz=ozs[idx];
        if (oz.y=='cylinder') return 0;

        let latlng=new L.LatLng(oz.a,oz.o);
        let next=idx+1<ozs.length ? new L.LatLng(ozs[idx+1].a,ozs[idx+1].o) : undefined;
        let previous=idx==0? undefined : new L.LatLng(ozs[idx-1].a,ozs[idx-1].o);

        if (previous===undefined && next===undefined) return 0;

        let az=0;
        if (previous===undefined) {
            az=Util.calcBearing(latlng,next);
        }
        else if (next===undefined) {
            az=Util.calcBearing(latlng,previous);
        }
        else {
            let az1=Util.calcBearing(latlng,next);
            let az2=Util.calcBearing(latlng,previous);
            az=Util.calcBisector(az1,az2);
        }
        return az;
    }
    drawLegacy(layer) {//legacy drawing
        let d=this.task;

        let task=new L.Polyline(d.wpt,{
            color: '#000680',
            opacity: .9,
            weight: 2
        });

        layer.addLayer(task);

        if ((d.perfType==19 || d.perfType==9 || d.perfType==13) && d.wpt.length>4) {
            let tri=new L.Polyline([d.wpt[1],d.wpt[3]],{
                color: '#000680',
                opacity: .9,
                weight: 2,
                dashArray: '5,7'
            });
            layer.addLayer(tri);
        }

        let declaredOz=[];


        for (let i=0; i<d.wptOzType.length; i++) {
            declaredOz[i]=true;
            switch(d.wptOzType[i]) {
                case 11:
                    layer.addLayer(
                        new Sector(d.wpt[i], 3000,30, d.wptData[i], {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 12:
                    layer.addLayer(
                        new Cylinder(d.wpt[i] , 500, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 13:
                    layer.addLayer(
                        new LimitedSector(d.wpt[i], 1000, 8, d.wptData[i], {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 14:
                    layer.addLayer(
                        new Line(d.wpt[i] , 500, 5, d.wptData[i], {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 15:
                    declaredOz[i]=false;
                    layer.addLayer(
                        new L.CircleMarker(d.wpt[i] , {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#00ffff',
                            fillOpacity: 0.5,
                            radius:4
                        })
                    );
                    break;
                case 16:
                    layer.addLayer(
                        new Cylinder(d.wpt[i] , d.wptData['y'], 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
            }
        }


        if (this.flightTrace) {
            for (let i = 0; i < d.fix.length; i++) {
                if (declaredOz[i]) {
                    layer.addLayer(new L.CircleMarker(this.flightTrace.fixes[d.fix[i]], {
                        color: '#000680',
                        opacity: .9,
                        weight: 1,
                        fill: true,
                        fillColor: '#00ffff',
                        fillOpacity: 0.5,
                        radius: 4
                    }));
                }
            }
        }

        for (let i=0 ; i<d.wpt.length; i++) {
            let marker = new L.Marker(d.wpt[i],{
                zIndexOffset: 20-i,
                icon: L.icon({iconUrl:'/etc/img/'+this._wpIconName(i,d.wpt.length)+'.png', iconSize:[19,32],iconAnchor:[0,31]})
            });
            layer.addLayer(marker);
        }
    }
    drawTransitional(layer) {//transitional drawing method

        let d=this.task;

        let g=this.task.taskData ? JSON.parse(this.task.taskData.g) : undefined;

        let task=new L.Polyline(d.wpt,{
            color: '#000680',
            opacity: .9,
            weight: 2
        });

        layer.addLayer(task);

        if (((d.perfType==19 || d.perfType==9 || d.perfType==13 || d.perfType==0x12000 || d.perfType==0x13000) && d.wpt.length>4) ||
            (d.wpt.length==8 && (d.perfType==0x15000 || d.perfType==0x16000)) ||
            (d.wpt.length==11 && (d.perfType==0x18000 || d.perfType==0x19000))
        ) {
            let tri=new L.Polyline([d.wpt[1],d.wpt[3]],{
                color: '#000680',
                opacity: .9,
                weight: 2,
                dashArray: '5,7'
            });
            layer.addLayer(tri);
        }

        let declaredOz=[];

        for (let i=0; i<d.wptOzType.length; i++) {
            declaredOz[i]=true;
            switch(g.w[i].y) {
                case 11:
                    layer.addLayer(
                        new Sector(d.wpt[i], 3000,30, d.wptData[i], {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 12:
                    layer.addLayer(
                        new Cylinder(d.wpt[i] , 500, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 13:
                    layer.addLayer(
                        new LimitedSector(d.wpt[i], g ? g.w[i].r*1000 : 1000, 8, d.wptData[i], {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 14:
                    layer.addLayer(
                        new Line(d.wpt[i] , g.w[i].r*1000, 5,g.w[i].z, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 15:
                    declaredOz[i]=false;
                    layer.addLayer(
                        new L.CircleMarker(d.wpt[i] , {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#00ffff',
                            fillOpacity: 0.5,
                            radius:4
                        })
                    );
                    break;
                case 16:
                    layer.addLayer(
                        new Cylinder(d.wpt[i] ,g ? g.w[i].r*1000:  d.wptData['y'], 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 17:
                    layer.addLayer(
                        new Cylinder(d.wpt[i] , g ? g.w[i].r*1000:  d.wptData['y'], 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
            }
        }

        if (g) {
            for (let i=0;i<d.fix.length;i++){
                if (declaredOz[i] && g.f[i]!==null) {
                    layer.addLayer(new L.CircleMarker(new L.LatLng(g.f[i].a, g.f[i].o), {
                        color: '#000680',
                        opacity: .9,
                        weight: 1,
                        fill: true,
                        fillColor: '#00ffff',
                        fillOpacity: 0.5,
                        radius: 4
                    }));
                }
            }
        }

        for (let i=0 ; i<d.wpt.length; i++) {
            let marker = new L.Marker(d.wpt[i],{
                zIndexOffset: 20-i,
                icon: L.icon({iconUrl:'/etc/img/'+this._wpIconName(i,d.wpt.length)+'.png', iconSize:[19,32],iconAnchor:[0,31]})
            });
            layer.addLayer(marker);
        }
    }

    _wpIconName(i,n) {
        if (i==0) return 'wptMarkerS';
        if ((i+1)==n) return 'wptMarkerF';
        return 'wptMarker'+i;
    }
}


export const Cylinder = L.Path.extend({
    initialize: function (latlng, radius, pixelRadius,  options) {
        L.Path.prototype.initialize.call(this, options);

        this._latlng = L.latLng(latlng);
        this._mRadius = radius!==undefined ? radius : this.options.defaultRadius;
        this._pixelRadius=pixelRadius!==undefined ? pixelRadius : this.options.defaultPixelRadius;
        this.ozType='cylinder';
    },

    options: {
        fill: true,
        color: '#000680',
        opacity: .9,
        weight: 1,
        fillColor: '#ffff00',
        fillOpacity: 0.5,
        defaultRadius: 500,
        defaultPixelRadius: 5,
        hideOnMinRadius:false
    },

    setLatLng: function (latlng) {
        this._latlng = L.latLng(latlng);
        return this.redraw();
    },

    setRadius: function (radius) {
        this._mRadius = radius;
        return this.redraw();
    },

    projectLatlngs: function () {
        let lngRadius = this._getLngRadius(),
            latlng2 = new L.LatLng(this._latlng.lat, this._latlng.lng - lngRadius),
            point2 = this._map.latLngToLayerPoint(latlng2);

        this._point = this._map.latLngToLayerPoint(this._latlng);
        this._radius = Math.max(Math.round(this._point.x - point2.x), 1);
    },

    getBounds: function () {
        let lngRadius = this._getLngRadius(),
            latRadius = (this._mRadius / 40075017) * 360,
            latlng = this._latlng,
            sw = new L.LatLng(latlng.lat - latRadius, latlng.lng - lngRadius),
            ne = new L.LatLng(latlng.lat + latRadius, latlng.lng + lngRadius);

        return new L.LatLngBounds(sw, ne);
    },

    getLatLng: function () {
        return this._latlng;
    },

    getPathString: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius;

        if (this._checkIfEmpty()) {
            return '';
        }

        if (this.options.hideOnMinRadius && this._radius < this._pixelRadius) return '';

        return "M" + p.x + "," + (p.y - r) +
            "A" + r + "," + r + ",0,1,1," +
            (p.x - 0.1) + "," + (p.y - r) + " z";

    },

    isScaledRadius:function() {
        return this._radius ? this._radius >= this._pixelRadius : false;
    },

    getRadius: function () {
        return this._mRadius;
    },
    _getLatRadius: function () {
        return (this._mRadius / 40075017) * 360;
    },

    _getLngRadius: function () {
        return this._getLatRadius() / Math.cos(Math.PI/ 180 * this._latlng.lat);
    },

    _checkIfEmpty: function () {
        if (!this._map) {
            return false;
        }
        let vp = this._map._pathViewport,
            r = this._radius,
            p = this._point;

        return p.x - r > vp.max.x || p.y - r > vp.max.y ||
            p.x + r < vp.min.x || p.y + r < vp.min.y;
    }
});

export const cylinder = function (latlng, radius, pixelRadius,  options) {
    return new Cylinder(latlng, radius, pixelRadius,  options);
};

export const LimitedSector = Cylinder.extend({
    initialize: function (latlng, radius, pixelRadius, azimouth, options) {
        Cylinder.prototype.initialize.call(this, latlng, radius, pixelRadius, options);
        this._azimouth = azimouth;
        this._theta=((this._azimouth+135) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
        this.ozType='limitedSector';
    },

    options: {
        defaultRadius: 1000,
        defaultPixelRadius: 8
    },

    getPathString: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p1=new L.Point(this._sinTheta*r,-this._cosTheta*r),
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return '';
        }


        return "M" + p.x + "," + p.y +
            "L"+(p.x+p1.x)+" "+(p.y+p1.y)+
            "A" + r + "," + r + ",0,0,1," +
            (p.x+p2.x) + "," + (p.y+p2.y) + " z";

    },

    setAzimouth: function(az) {
        this._azimouth = az;
        this._theta=((this._azimouth+135) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
    }

});

export const limitedSector = function (latlng, radius, pixelRadius, azimouth, options) {
    return new LimitedSector(latlng, radius, pixelRadius, azimouth, options);
};


export const Sector = L.Path.extend({
    initialize: function (latlng, minRadius, pixelRadius, azimouth, options) {
        L.Path.prototype.initialize.call(this, options);

        this._latlng = L.latLng(latlng);
        this._mRadius = minRadius!==undefined ? minRadius : this.options.defaultRadius;
        this._pixelRadius=pixelRadius!==undefined ? pixelRadius : this.options.defaultPixelRadius;

        this.ozType='sector';
    },

    options: {
        fill: true,
        color: '#000680',
        opacity: .9,
        weight: 0,
        weight1: 1,
        fillColor: '#ffff00',
        fillOpacity: 0.5,
        defaultRadius: 3000,
        defaultPixelRadius: 30
    },

    _initPath: function () {
        this._container = this._createElement('g');
        this._path = this._createElement('path');
        this._path1 = this._createElement('path');
        this._container.appendChild(this._path);
        this._container.appendChild(this._path1);
        this._updateStyle1();
    },

    _updatePath: function () {
        if (isNaN(this._sinTheta) || isNaN(this._cosTheta)) return;
        let p0=this.getPathString();
        let p1=this.getPathString1();
        this._path.setAttribute('d',p0);
        this._path1.setAttribute('d',p1);
    },

    getPathString1: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p1=new L.Point(this._sinTheta*r,-this._cosTheta*r),
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return 'M 0,0';
        }

        return "M" + (p.x+p2.x) + "," + (p.y+p2.y) +
            "L"+p.x+" "+p.y+
            "L"+(p.x+p1.x)+" "+(p.y+p1.y);
    },

    getPathString: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p1=new L.Point(this._sinTheta*r,-this._cosTheta*r),
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return 'M 0,0';
        }


        return "M" + p.x + "," + p.y +
            "L"+(p.x+p1.x)+" "+(p.y+p1.y)+
            "A" + r + "," + r + ",0,0,1," +
            (p.x+p2.x) + "," + (p.y+p2.y) + " z";
    },

    _updateStyle1: function () {
        this._path1.setAttribute('stroke', this.options.color);
        this._path1.setAttribute('stroke-opacity', this.options.opacity);
        this._path1.setAttribute('stroke-width', this.options.weight1);
        this._path1.setAttribute('fill', 'none');
    },

    setLatLng: function (latlng) {
        this._latlng = L.latLng(latlng);
        return this.redraw();
    },

    setRadius: function (radius) {
        this._mRadius = radius;
        return this.redraw();
    },

    projectLatlngs: function () {
        let lngRadius = this._getLngRadius(),
            latlng2 = new L.LatLng(this._latlng.lat, this._latlng.lng - lngRadius),
            point2 = this._map.latLngToLayerPoint(latlng2);

        this._point = this._map.latLngToLayerPoint(this._latlng);
        this._radius = Math.max(Math.round(this._point.x - point2.x), 1);
    },

    getBounds: function () {
        let lngRadius = this._getLngRadius(),
            latRadius = (this._mRadius / 40075017) * 360,
            latlng = this._latlng,
            sw = new L.LatLng(latlng.lat - latRadius, latlng.lng - lngRadius),
            ne = new L.LatLng(latlng.lat + latRadius, latlng.lng + lngRadius);

        return new L.LatLngBounds(sw, ne);
    },

    getLatLng: function () {
        return this._latlng;
    },

    getRadius: function () {
        return this._mRadius;
    },
    _getLatRadius: function () {
        return (this._mRadius / 40075017) * 360;
    },

    _getLngRadius: function () {
        return this._getLatRadius() / Math.cos(Math.PI/180 * this._latlng.lat);
    },

    _checkIfEmpty: function () {
        if (!this._map) {
            return false;
        }
        let vp = this._map._pathViewport,
            r = this._radius,
            p = this._point;

        return p.x - r > vp.max.x || p.y - r > vp.max.y ||
            p.x + r < vp.min.x || p.y + r < vp.min.y;
    },

    setAzimouth: function(az) {
        this._azimouth = az;
        this._theta=((this._azimouth+135) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
    }


});

export const sector = function (latlng, minRadius, pixelRadius, azimouth, options) {
    return new Sector(latlng, minRadius, pixelRadius, azimouth, options);
};


export const Line = Cylinder.extend({
    initialize: function (latlng, radius, pixelRadius, azimouth, options) {
        Cylinder.prototype.initialize.call(this, latlng, radius, pixelRadius, options);
        this._azimouth = azimouth;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
        this.ozType='line';
    },

    options: {
        defaultRadius: 500,
        defaultPixelRadius: 5
    },

    getPathString: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p1=new L.Point(this._sinTheta*r/5,-this._cosTheta*r/5),
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return '';
        }

        return "M" + (p.x+p2.x+p1.x) + "," + (p.y+p2.y+p1.y) +
            "L" + (p.x+p2.x) + "," + (p.y+p2.y) +
            "L"+(p.x-p2.x)+" "+(p.y-p2.y)+
            "L"+(p.x-p2.x+p1.x)+" "+(p.y-p2.y+p1.y);

    },

    setAzimouth: function(az) {
        this._azimouth = az;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
    }

});

export const line = function (latlng, radius, pixelRadius, azimouth, options) {
    return new Line(latlng, radius, pixelRadius, azimouth, options);
};

export const SgpStartArea = Cylinder.extend({
    initialize: function (latlng, radius, pixelRadius, azimouth, options) {
        Cylinder.prototype.initialize.call(this, latlng, radius, pixelRadius, options);
        this._azimouth = azimouth;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
        this.ozType='line';
    },

    options: {
        defaultRadius: 500,
        defaultPixelRadius: 5,
        color: '#000680',
        opacity: .9,
        weight: 1,
        fill: true,
        fillColor: 'none',
        fillOpacity: 0.5,
        dashArray:'10,10'
    },

    getPathString: function () {
        let p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return '';
        }

        return 	"M"+(p.x-p2.x)+" "+(p.y-p2.y)+
            "A"+r+","+r+" 0 1,1"+(p.x+p2.x) + "," + (p.y+p2.y);

    },

    setAzimouth: function(az) {
        this._azimouth = az;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
    }

});

export const sgpStartArea = function (latlng, radius, pixelRadius, azimouth, options) {
    return new SgpStartArea(latlng, radius, pixelRadius, azimouth, options);
};