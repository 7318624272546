import React from 'react';
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {CCI, SGP} from "./sgp-results-react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#333333',
        color: '#ffffff',
        fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {

    },
}));

const ResultTable = ({comp,day,dayIndex}) => {

    if (dayIndex===-1) {
        //show schedule

        return (
            <>
                <TableContainer component={Box} mt={2} mb={1}>
                    <Table size="small" sx={{ minWidth: 700 }} aria-label="result table">
                        <TableHead>
                            <TableRow sx={{backgroundColor:'#333333',color:'#ffffff',fontWeight:700}}>
                                <StyledTableCell align={'left'}>Day</StyledTableCell>
                                <StyledTableCell align={'left'}>Race</StyledTableCell>
                                <StyledTableCell align={'left'}>Start time*</StyledTableCell>
                                <StyledTableCell align={'left'}>Winner</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                comp.i.map( day => {

                                    const winner = (day.y===1 && (!day.r || day.r.u>=1) ? day.w ? Object.values(comp.p).filter(pilot => pilot.d === day.w) : [] : []).map( p => p.f+" "+p.l).join("")

                                    return (
                                        <StyledTableRow key={day.i}>
                                            <StyledTableCell>{moment.utc(day.d).format('dddd, D MMMM, yyyy')}</StyledTableCell>
                                            <StyledTableCell>{day.l}</StyledTableCell>
                                            <StyledTableCell>{day.v ? moment.utc(day.a).format('HH:mm') :  day.a ? "Planned "+ moment.utc(day.a).format('HH:mm') : ""}</StyledTableCell>
                                            <StyledTableCell>{winner}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mb={2} mr={2}><Typography align={'right'} sx={{fontSize:'12px',fontStyle:'italic'}}>*All times local</Typography> </Box>
            </>
        )
    }

    if (dayIndex === comp.i.length) {
        //show totals table

        const days=comp.i;
        const day=days[comp.j];
        const totals=day.r.t;
        const pilots=comp.p;

        if (!totals) return (
            <Box py={10} my={2} sx={{bgcolor:'#f0f0f0'}} align={'center'}>
                <Typography variant={'body1'}>NO RESULTS YET</Typography>
            </Box>
        )

        const totalsList = Object.keys(totals).map( k => {return { id:k ,pts: totals[k]}})
        const placingsMap=SGP.calcPlacingsMap(comp);
        const totalSpeeds=comp.t.s;

        totalsList.sort(function(a,b){
            if (a.pts!==b.pts) {
                return a.pts > b.pts ? -1 : 1;
            }
            let amap=placingsMap[a.id];
            let bmap=placingsMap[b.id];

            if (!amap) return 1;
            if (!bmap) return -1;

            for (let i=1;i<25;i++) {
                let at=amap[i+""];
                let bt=bmap[i+""];
                if (at && !bt) return -1;
                if (bt && !at) return 1;
                if (at===bt) continue;
                return at>bt ? -1 : 1;
            }

            //consider speeds in the event of a further tie
            if (totalSpeeds) {
                if (totalSpeeds[a.id]!==undefined && totalSpeeds[b.id]===undefined) return -1;
                if (totalSpeeds[a.id]===undefined && totalSpeeds[b.id]!==undefined) return 1;
                if (totalSpeeds[a.id]>totalSpeeds[b.id]) return -1;
                if (totalSpeeds[a.id]<totalSpeeds[b.id]) return 1;
            }

            return 0;
        })

        return (
            <>
                <TableContainer component={Box} my={2}>
                    <Table size="small" sx={{ minWidth: 700 }} aria-label="result table">
                        <TableHead>
                            <TableRow sx={{backgroundColor:'#333333',color:'#ffffff',fontWeight:700}}>
                                <StyledTableCell align={'center'}>Pos</StyledTableCell>
                                <StyledTableCell align={'center'}>ID</StyledTableCell>
                                <StyledTableCell align={'left'}>Pilot</StyledTableCell>
                                <StyledTableCell align={'left'}>&nbsp;</StyledTableCell>
                                <StyledTableCell align={'left'}>Glider</StyledTableCell>
                                <StyledTableCell align={'right'}>Points</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                totalsList.map( (ttl, i) => {
                                    const pilot=pilots[ttl.id]
                                    const pilotEntry= pilot.f+" "+pilot.l
                                    const pts=ttl.pts ? ttl.pts : ''

                                    return (
                                        <StyledTableRow key={ttl.id}>
                                            <StyledTableCell align={'center'}>{i+1}</StyledTableCell>
                                            <StyledTableCell align={'center'}><div style={{display:'inline-block',background:'#ffffff',padding:'1px 3px',borderRadius:'4px',verticalAlign:'text-bottom',fontSize:'10pt',border:'1px solid #999999'}}>{pilot.d}</div></StyledTableCell>
                                            <StyledTableCell align={'left'}>{pilotEntry}</StyledTableCell>
                                            <StyledTableCell align={'left'}><img src={SGP.flagsUrl+pilot.z.toLowerCase()+'.svg'} width="21" height="14" /></StyledTableCell>
                                            <StyledTableCell align={'left'}>{pilot.s}</StyledTableCell>
                                            <StyledTableCell align={'right'}>{pts}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    if (day.y===3 || day.y===4) {
        return  (
            <Box py={10} my={2} sx={{bgcolor:'#f0f0f0'}} align={'center'}>
                <Typography variant={'body1'}>{day.y===3 ? "RACE CANCELLED - " : "REST DAY - "} NO RESULTS</Typography>
            </Box>
        )
    }

    const scores=day.r.s
    const totals=day.r.t

    const hasNotes = scores.some(sc => sc.n !==undefined)

    let winningTime=0

    return (
        <>
            <TableContainer component={Box} my={2}>
                <Table size="small" sx={{ minWidth: 700 }} aria-label="result table">
                    <TableHead>
                        <TableRow sx={{backgroundColor:'#333333',color:'#ffffff',fontWeight:700}}>
                            <StyledTableCell align={'center'}>Pos</StyledTableCell>
                            <StyledTableCell align={'center'}>ID</StyledTableCell>
                            <StyledTableCell align={'left'}>Pilot</StyledTableCell>
                            <StyledTableCell align={'left'}>&nbsp;</StyledTableCell>
                            <StyledTableCell align={'left'}>Glider</StyledTableCell>
                            <StyledTableCell align={'right'}>Scoring time</StyledTableCell>
                            <StyledTableCell align={'right'}>Scoring speed</StyledTableCell>
                            <StyledTableCell align={'right'}>Penalty</StyledTableCell>
                            <StyledTableCell align={'right'}>Points</StyledTableCell>
                            <StyledTableCell align={'right'}>Total</StyledTableCell>
                            <StyledTableCell align={'right'}>&nbsp;</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scores.map((res,i) => {

                            if (i===0) winningTime=res.t;

                            let hasResultDetails=res.u==2 || res.u==3 || res.u==4;

                            let pilot=comp.p[res.h];
                            let pilotEntry= pilot.f+" "+pilot.l
                            let spd=res.u!==5 && res.s  ? <>{res.s.toFixed(1)} <span style={{fontSize: '8pt'}}>km/h</span></> : res.d ? <> {res.d.toFixed(1)} <span style={{fontSize: '8pt'}}>km</span></> : <></>
                            if (!hasResultDetails && res.u!==5) spd=""
                            let tme=hasResultDetails && res.t ? (i===0 ? CCI.Util.toHmsDurationString(res.t) :  CCI.Util.toDeltaDurationString(res.t-winningTime) ) : " ";
                            let pnl=res.l ? <>{res.l}<span style={{fontSize: '8pt'}}>s</span></> : <></>
                            let pts=res.p ? (res.p < 10 ? ' ' : '')+res.p : ' ';
                            let ttl=day.y!==1 || !totals ? " " : totals[res.h] ? totals[res.h] : " ";


                            return (
                                <StyledTableRow key={i}>
                                    <StyledTableCell align={'center'}>{res.r}</StyledTableCell>
                                    <StyledTableCell align={'center'}><div style={{display:'inline-block',background:'#ffffff',padding:'1px 3px',borderRadius:'4px',verticalAlign:'text-bottom',fontSize:'10pt',border:'1px solid #999999'}}>{pilot.d}</div></StyledTableCell>
                                    <StyledTableCell align={'left'}>{pilotEntry}</StyledTableCell>
                                    <StyledTableCell align={'left'}><img src={SGP.flagsUrl+pilot.z.toLowerCase()+'.svg'} width="21" height="14" /></StyledTableCell>
                                    <StyledTableCell align={'left'}>{pilot.s}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{tme}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{spd}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{pnl}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{pts}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{ttl}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{res.w!==0 && <a href={'http://www.crosscountry.aero/flight/view/'+res.w} target="_blank" style={{padding:'1px 3px',border:'solid 1px #bbbbbb',borderRadius:'4px',background:'#eeeeee',textDecoration:'none',color:'#333333',fontSize:'8pt'}}>view</a>}</StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            { hasNotes &&
                <Box my={2} px={'12px'} pt={'6px'} pb={'12px'} sx={{textAlign:'left',borderTop:'1px solid #999999',borderBottom:'1px solid #999999'}}>
                    { scores.map((sc) => {
                        return sc.n &&
                        <Box key={sc.j} sx={{mt:'6px'}}>
                            <div style={{float:'left',width:'50px'}}>
                                <div style={{display:'inline-block',padding:'1px 3px',border:'solid 1px #999999',borderRadius:'4px',fontSize:'12px'}}>
                                    {sc.j}
                                </div>
                            </div>
                            <div style={{display:'inline-block',whiteSpace:'pre',fontSize:'14px'}}>
                                {sc.n}
                            </div>
                        </Box>
                    })}
                </Box>
            }
        </>
    )
}

export default ResultTable;