import React from 'react';
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {useSelector} from "react-redux";

const DateInfo = ({ flight, variant = 'medium' }) => {
    const { format } = useSelector(state => state.session)

    let dateFormat = format.date.l

    switch (variant) {
        case 'large': dateFormat = format.date.xl; break;
        case 'small': dateFormat = format.date.s; break;
        default: dateFormat = format.date.l;
    }

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
            <CalendarTodayIcon className={'icon'} fontSize={'small'}/>
            <Box>
                {moment.utc(flight.date).format(dateFormat)}
            </Box>
        </Box>
    );
};

export default DateInfo;