import React from 'react'
import Authenticated from "../Authenticated";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useSearchParams} from "react-router-dom";
import {useGetFlightsQuery} from "../../slices/apiSlice";
import FlightList from "../FlightList";
import {useSelector} from "react-redux";
import {Skeleton} from "@mui/material";

export default function Logbook () {
    const [searchParams] = useSearchParams()
    const session = useSelector(state => state.session)

    const pilotId = session.pilot?.id
    const skip = pilotId === undefined

    //make up for the fact that pages are zero-based in the query but not in the index
    let page = searchParams.get('page') ?? 0

    const {
        data: flights,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetFlightsQuery({pilotId,page},{skip})


    return (
        <Authenticated>
            {
                isSuccess ?
                <FlightList data={flights} pilotId={pilotId} isOwn={true}/>
            :
                <>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </>
            }
        </Authenticated>
    )
}