import { createSlice } from '@reduxjs/toolkit'
import { Buffer } from "buffer";

export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
export const AUTHENTICATING = 'AUTHENTICATING'
export const AUTHENTICATED = 'AUTHENTICATED'


const sessionDefaults = {
    country: 'DE',
}

const initSession = {
    status: NOT_AUTHENTICATED,
    user: undefined,
    pilot: undefined,
    token:undefined,
    issued:undefined,
    expires:undefined,
    scope:undefined,
    region: {
        country: sessionDefaults.country
    },
    format: {
            date: {
                s: 'DD/MM/yyyy',
                m: 'D MMM yyyy',
                l: 'D MMMM yyyy',
                xl: 'dddd, D MMMM yyyy',
            }
    },
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState: initSession,
    reducers: {
        setToken: (state,action) => {
            const token = action.payload.token
            state.token = token
            const data = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
            state.issued = data.iat
            state.expires = data.exp
            state.scope = data.scope
            localStorage.setItem('token',action.payload.token)
        },
        authenticating: (state) => {
            state.status = AUTHENTICATING
        },
        login: (state, action) => {
            state.user = action.payload.user
            state.pilot= action.payload.pilot
            state.status = AUTHENTICATED
        },
        logout: state => {
            state.status = NOT_AUTHENTICATED
            delete state.user
            delete state.pilot
            delete state.token
            localStorage.removeItem('token')
        },
        setCountry: (state,action) => {
            state.region = action.payload.country
        },
    }
})

export const { login, logout, authenticating, setCountry, setToken } = sessionSlice.actions

export default sessionSlice.reducer