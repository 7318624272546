import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {AUTHENTICATED, logout} from "../../slices/sessionSlice";

const pages = [
    {name:'Home',path:'/'},
    {name:'Club',path:'/club'},
    {name:'Community',path:'/community'},
    {name:'Competition',path:'/competition'},
    {name:'Analysis',path:'/analysis'},
    {name:'Planning',path:'/planning'},
];
const settings = ['Profile'];


function MenuBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const navigate = useNavigate()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const session = useSelector(state => state.session)
    const dispatch = useDispatch()

    const handleLogin = () => {
        navigate('/')
    }

    const handleLogout = () => {
        dispatch(logout())
        navigate('/')
    }

    const handleRegister = () => {
        navigate('/register')
    }

    return (
        <>
            { session.status == AUTHENTICATED &&
            <AppBar position="static" sx={{backgroundColor:'#214568'}}>
                <Container maxWidth="xl">
                    <Toolbar variant={'dense'} sx={{minHeight:'46px',height:'46px'}} disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.path}  onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center"><Link style={{textDecoration:'none',color:'inherit'}}  to={`${page.path}`}>{page.name}</Link></Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Link to={'/'}>
                        <Box
                            sx={{
                                mr: 5,
                                display: { xs: 'flex' },
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src='/etc/img/cc-logo-v3-white.png' style={{height:'24px'}}/>
                        </Box>
                        </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.path}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, display: 'block' }}
                                    color={'inherit'}
                                >
                                    <Link style={{textDecoration:'none',color:'white'}}  to={`${page.path}`}>{page.name}</Link>
                                </Button>
                            ))}
                        </Box>
                        {session.status == AUTHENTICATED ? (
                            <>
                                <Box>
                                        <Typography sx={{marginRight:'12px'}}>{session.user.firstname} {session.user.lastname}</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0 }}>

                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar sx={{bgcolor:'#777777',height:32,width:32, fontSize:'14px'}}>{session.user.firstname.substring(0,1)}{session.user.lastname.substring(0,1)}</Avatar>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {settings.map((setting) => (
                                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                        <MenuItem key='logoutBtn' onClick={() => {
                                            handleLogout()
                                            handleCloseUserMenu()
                                        }}>
                                            <Typography textAlign="center">Sign out</Typography>
                                        </MenuItem>

                                    </Menu>
                                </Box>
                            </>
                            ) : (
                                <Box sx={{display:'flex'}}>
                                    <Button
                                        key='registerButton'
                                        onClick={handleRegister}
                                        sx={{ my: 2, color: 'white', display: 'block' , marginRight:'1em'}}
                                    >
                                        Join now
                                    </Button>
                                    <Button
                                    key='loginBtn'
                                    variant={'outlined'}
                                    color={'inherit'}
                                    onClick={handleLogin}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                    Sign in
                                    </Button>
                                </Box>
                            )}
                    </Toolbar>
                </Container>
            </AppBar>
            }
        </>
    );
}
export default MenuBar;