import React, {useContext} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet'
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./map.css"
import CCMap from "./CCMap";

const Map = ({lat,lon,zoom,children,height}) =>{


    return (
        <MapContainer center={[lat, lon]} zoom={zoom}  style={{height:height}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <CCMap>
                {children}
            </CCMap>
        </MapContainer>
    )
}

export default Map;
