import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useAuthenticateMutation, useGetFlightPerformancesQuery} from "../slices/apiSlice";
import {useNavigate} from "react-router-dom";
import {Alert, Dialog} from "@mui/material";
import {useEffect} from "react";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {'Crosscountry Aero Ltd'}
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}


export default function Login({modal}) {

    const navigate = useNavigate()

    let open=true


    const [authenticate, { isLoading, isError: hasAuthenticationError, error, isSuccess: hasAuthenticated }] =
        useAuthenticateMutation()



    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        authenticate({
            username: data.get('username'),
            password: data.get('password'),
        })
    };

    if (hasAuthenticated) {
        if (modal) open=false
    }

    const components =(
        <Container component="main" maxWidth="xs" sx={{margin: modal ? '2em 1em' : ''}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {modal &&
                    <Box sx={{marginBottom: '1em'}}>
                        <Link href={'/'}>
                            <img src='/etc/img/cc-logo-v3-black.png' style={{width: '12em'}}/>
                        </Link>
                    </Box>
                }
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    {hasAuthenticationError &&
                        <Alert severity="error" sx={{margin: '0.5em 0 1em 0'}}>Incorrect username or password.
                            Please try again</Alert>
                    }
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                {"Don't have an account? Sign Up, its free!"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )

    return (

            modal ?
                <Dialog open={open}>
                    {components}
                </Dialog>
                :
                components
    );
}