import React, {useEffect} from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import Container from "@mui/material/Container";
import Authenticated from "../../components/Authenticated";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

const Crosscountry = () => {
    const navigate = useNavigate()

    const [tabValue, setTabValue] = React.useState()

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        navigate(newValue)
    };


    return (
        <Authenticated>
            <Container>
                <Box sx={{display:'flex',justifyContent:'center'}}>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Flights" value="flights" />
                            <Tab label="Pilots" value="pilots" />
                            <Tab label="Airfields" value="airfields" />
                            <Tab label="Sailplanes" value="sailplanes" />
                        </TabList>
                    </TabContext>
                </Box>
                <Outlet/>
            </Container>
        </Authenticated>
    );
}

export default Crosscountry;

