import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import CompetitionList from "./CompetitionList";
import {useGetEventsQuery} from "../../slices/apiSlice";


const CompetitionHome = () => {
    const {data: competitionEditions, isSuccess} = useGetEventsQuery()


    return (
        <>
            <Box>
                <Typography variant={'h6'} my={4}>Competition</Typography>
            </Box>
            <Box sx={{display:'flex',flexDirection:{xs:'column',md:'row-reverse'}}}>
                <Stack spacing={2} sx={{minWidth:'30%',marginLeft:{xs:'0px',md:'48px'}}}>
                    <Typography variant={'h6'}>Featured competitions</Typography>
                    <Box>
                        <img src={'/etc/img/sgp/sgp-logo.png'} alt="FAI Sailplane Granf Prix" style={{width:'100%',objectFit:'fit'}}/>
                    </Box>
                    <Box>
                        <img src={'/etc/img/cc-og-logo.png'} alt="Crosscountry Aero pilot ranking" style={{width:'100%',objectFit:'fit'}}/>
                    </Box>
                    <Box>
                        <img src={'/etc/img/bitner-logo-banner.png'} alt="Polish Online Championship" style={{width:'100%',objectFit:'fit'}}/>
                    </Box>
                    <Box>
                        <img src={'/etc/img/wag_logo.png'} alt="World Air Games - Dubai" style={{width:'100%',objectFit:'fit'}}/>
                    </Box>
                </Stack>
                <CompetitionList events={competitionEditions}/>
            </Box>
        </>
    );
};

export default CompetitionHome;