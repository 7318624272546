import React from 'react';
import Box from "@mui/material/Box";
import Authenticated from "../components/Authenticated";
import Login from "./Login";
import Typography from "@mui/material/Typography";
import Logbook from "../components/Logbook";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Flights from "./Flights";


export default function Home () {

    const [tabValue, setTabValue] = React.useState('flights');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const notAuthenticatedView = () => {
        return (
            <Box>
                <Box sx={{
                    minHeight: 'calc(100vh - 0px)',
                    display: 'flex',
                    alignItems: 'stretch',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundImage: 'url("/etc/home-img/photo-0001.jpg")'
                }}>
                    <Box flexGrow={1} sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'space-between',
                        background:'#00000077',
                    }}>
                        <Box sx={{
                            py:'2em',
                        }}>
                            <Box>
                                <img src={'/etc/img/cc-logo-v3-white.png'} style={{width:'20em'}}/>
                            </Box>
                            <Typography variant={'h3'} component={'h2'} sx={{color:'#ffffff'}}>
                                Your soaring home on the cloud
                            </Typography>
                        </Box>
                        <Box sx={{
                            py:'2em',
                            color:'#ffffff',
                        }}>
                            Other stuff goes here
                        </Box>
                    </Box>
                    <Box flexGrow={2} sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                    }}>
                        <Box sx={{
                            bgcolor:'#ffffff',
                            p:'1.5em 1.5em',
                        }}>
                            <Login/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }


    return (
        <Authenticated notAuthenticatedView={notAuthenticatedView()}>
               <TabContext value={tabValue}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <TabList onChange={handleTabChange} aria-label="Pilot logbook">
                            <Tab label="Posts" value="posts" />
                            <Tab label="Flights" value="flights" />
                            <Tab label="Soaring" value="soaring" />
                            <Tab label="Competition" value="comps" />
                            <Tab label="Logbook" value="logbook" />
                        </TabList>
                    </Box>
                    <TabPanel value="posts" sx={{p:'1.5em 0 0 0'}}>Posts</TabPanel>
                    <TabPanel value="flights" sx={{p:'1.5em 0 0 0'}}>
                        <Flights/>
                    </TabPanel>
                    <TabPanel value="soaring" sx={{p:'1.5em 0 0 0'}}>Soaring</TabPanel>
                    <TabPanel value="comps" sx={{p:'1.5em 0 0 0'}}>Competitions</TabPanel>
                    <TabPanel value="logbook" sx={{p:'1.5em 0 0 0'}}><Logbook/></TabPanel>
                </TabContext>
        </Authenticated>
    )
}