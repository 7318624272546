import React from "react";

const FlightContext = React.createContext({
    flightId: undefined,
    metadata:undefined,
    flightTrace:undefined,
    d4:undefined,
    ccmap:undefined,
    barogram:undefined,
    performances:undefined,
});

export default FlightContext;