import React, {useContext, useEffect} from 'react';
import Map from '../../components/Map'
import Grid from "@mui/material/Grid";
import {useGetFlightMetadataQuery, useGetFlightPerformancesQuery, useGetFlightQuery} from "../../slices/apiSlice";
import FlightHeader from "./FlightHeader";
import FlightInfo from "./FlightInfo";
import {Skeleton} from "@mui/material";
import Barogram from "../Map/Barogram";
import FlightContext from "./FlightContext";
import MapControls from "../Map/MapControls";
import MapFlightRenderer from "../Map/MapFlightRenderer";
import MapTaskRenderer from "../Map/MapTaskRenderer";

const FlightView = ({flightId}) => {

    const flightContext = useContext(FlightContext)
    const [ flight, setFlight ] = React.useState()
    const [ skip, setSkip] = React.useState(true)

    const {
        data: flightData,
        isSuccess : isFlightSuccess,
    } = useGetFlightQuery(flightId)

    const {
        data: flightMetadata,
        isSuccess : isMetaSuccess,
    } = useGetFlightMetadataQuery(flight, {skip})

    const {
        data: performances,
        isSuccess : isPerformancesSuccess,
    } = useGetFlightPerformancesQuery(flightId, {skip})

    useEffect(()=>{
        if (isFlightSuccess) {
            setFlight(flightData)
            flightContext.flight=flightData
            setSkip(false)
        }
    },[isFlightSuccess])

    useEffect(()=>{
        if (isMetaSuccess) {
            flightContext.metadata=flightMetadata
        }
    },[isMetaSuccess])


    const performance = isPerformancesSuccess && performances.find( p => p.id === flight.bestPerformanceId)

    return (
        <>{
            flight ?
                <Grid container spacing={1} sx={{marginTop: '1rem'}}>
                    <Grid item xs={12}>
                        <FlightHeader flight={flight} performance={performance}/>
                    </Grid>
                    <Grid item xs={3}>
                        <FlightInfo flight={flight} performances={performances}/>
                    </Grid>
                    <Grid item xs={9}>
                        {isMetaSuccess ?
                            <Map lat={flightMetadata.m.a} lon={flightMetadata.m.o} zoom={flightMetadata.m.z}
                                 height={'640px'}>
                                <MapControls/>
                                <MapFlightRenderer>
                                    <MapTaskRenderer/>
                                </MapFlightRenderer>
                            </Map>
                            :
                            <Skeleton variant="rectangular" width={'100%'} height={640}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Barogram/>
                    </Grid>
                </Grid>
                :
                <Skeleton height={640}/>
        }</>
    );
}

export default FlightView