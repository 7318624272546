import React, {useContext, useEffect, useRef} from 'react';
import {useGet4DQuery} from "../../slices/apiSlice";
import {Data4D} from "../../lib/cc-map";
import Raphael from 'raphael';
import FlightContext from "../Flight/FlightContext";
import {Skeleton} from "@mui/material";

const Barogram = () => {

    const flightContext = useContext(FlightContext)
    const flight = flightContext.flight

    const node = useRef(null);

    const {
        data: d4,
        isLoading,
        isSuccess: loaded4d,
        isError,
        error
    } = useGet4DQuery(flight.id)


    useEffect(() => {
        if(loaded4d && node.current) {
            initBarogram(node.current, flight, d4)
        }
    })


    return (
        <div style={{height:'150px'}} ref={node}>
            {!loaded4d &&
                <Skeleton variant="rectangular" width={'100%'} height={150}/>
            }
        </div>
    )

    function initBarogram(el, flight, d4) {
        el.innerHTML=''
        let data4d=new Data4D(d4)
        let barogramObj = new BarogramImpl(el,flightContext, flight,data4d,)
        flightContext.d4=data4d
        flightContext.barogram=barogramObj
    }
}

export class BarogramImpl {
    constructor (el, flightContext, flight, d4, flightData, options) {
        this.options = Object.assign({},options)

        this.flightContext = flightContext
        this.flight = flight
        this.container= el;
        this.d4=d4;
        this.flightData=flightData;
        if (this.flightData) this.d4=flightData.d4;

        this.draw();
    }

    draw() {
        let t=this;
        if (this.el!==undefined) this.el.remove();

        let mapBaro=this;
        let baroDiv=this.container;
        let d4=this.d4;

        let fdToBr=this.flight.takeoffFix ? this.flight.takeoffFix.brec : 0
        let fdRlBr=this.flight.releaseFix ? this.flight.releaseFix.brec : 0
        let fdEdBr=this.flight.endFix ? this.flight.endFix.brec : 0
        let fdLdBr=this.flight.landFix ? this.flight.landFix.brec : 0

        let width=baroDiv.clientWidth;
        let height=baroDiv.clientHeight;
        let widthOff=0;
        let heightOff=0;
        let a=0;
        let b=d4.time.length-1;
        let count=d4.time.length;
        let maxHeightBar=Math.ceil(d4.maxHeight/100)*100;
        let minHeightBar=Math.floor(d4.minHeight/100)*100;
        if (minHeightBar>0) minHeightBar=0;
        let xscale=width/(d4.time[b]-d4.time[a]);
        let yscale=height/((maxHeightBar-minHeightBar)/100+1)/100;
        let enlscale=height/1000;
        let xoffset=d4.time[a];
        let yoffset=minHeightBar;
        let timeStart=d4.time[t.flightData ? t.flightData.f.br : fdRlBr];
        let timeEnd=d4.time[t.flightData ? t.flightData.f.be : fdEdBr];

        mapBaro.xoffset=xoffset;
        mapBaro.xscale=xscale;
        mapBaro.widthOff=widthOff;
        mapBaro.yoffset=yoffset;
        mapBaro.yscale=yscale;
        mapBaro.heightOff=heightOff;

        mapBaro.el=Raphael(baroDiv, width, height);

        mapBaro.container.addEventListener('click',function (eve) {
            //added this line to remove a jquery dependency on the line below "left" variable
            let left = mapBaro.container.getBoundingClientRect().left + mapBaro.container.ownerDocument.defaultView.pageXOffset

            let ex=Math.floor((eve.pageX-left))+1-widthOff;
            let mT=xoffset+ex/xscale;
            for (let i=a; i<=b ; i++) {
                if (d4.time[i]>=mT) {
                    mapBaro.flightContext.ccmap.showFix(i);
                    break;
                }
            }
        });

        if (d4.enlInfo) {
            let enlVals=new Array(width);

            for (let i=a ; i<=b ; i++) {
                let x=Math.round((d4.time[i]-xoffset)*xscale);
                if (enlVals[x]) {
                    if (enlVals[x]<d4.enl[i]) enlVals[x]=d4.enl[i];
                }
                else {
                    enlVals[x]=d4.enl[i];
                }
            }

            let enlPath="M"+widthOff+","+(height+heightOff)+"L";

            for (let i=0 ; i<width ; i++) {
                let y=Math.round(height-enlVals[i]*enlscale)+heightOff;
                if (isNaN(y)) continue;
                enlPath+=(i+widthOff)+" "+y+" ";
            }
            enlPath+=(width+widthOff)+" "+(height+heightOff);
            mapBaro.el.path(enlPath).attr({fill:"#ffd96d"}).attr({stroke:'none'});
        }

        for (let h=yoffset; h<=maxHeightBar ; h=h+100) {
            let y=Math.round(height-(h-yoffset)*yscale)+heightOff;
            let line=mapBaro.el.path('M'+widthOff+' '+y+'L'+(width-widthOff)+' '+y);
            if (h % 1000 == 0) {
                line.attr({stroke:'#DD6E24'});
                mapBaro.el.text(5,y-7,''+h+' m').attr({font:'10px arial'}).attr({'text-anchor': 'start'}).attr({fill:'#DD6E24'});
            }
            else if (h % 500 == 0) {
                line.attr({stroke:'#DDA681'});
            }
            else {
                line.attr({stroke:'#DDCFC6'});
            }
        }

        let z=Math.round((timeStart-xoffset)*xscale)+widthOff;
        mapBaro.el.path('M'+z+' '+heightOff+'L'+z+' '+(height-heightOff)).attr({stroke:'green'});
        mapBaro.el.text(z+8,height-heightOff-8,"S").attr({font:'12px arial'}).attr({fill:'green'});
        z=Math.round((timeEnd-xoffset)*xscale)+widthOff;
        mapBaro.el.path('M'+z+' '+heightOff+'L'+z+' '+(height-heightOff)).attr({stroke:'red'});
        mapBaro.el.text(z-8,height-heightOff-8,"E").attr({font:'12px arial'}).attr({fill:'red'});

        for (let t=timeStart+600000 ; t<timeEnd ; t=t+600000) {
            let x=Math.round((t-xoffset)*xscale)+widthOff;
            let line=mapBaro.el.path('M'+x+' '+heightOff+'L'+x+' '+(height-heightOff));
            if ((t-timeStart) % 3600000 == 0) {
                line.attr({stroke:'#DD6E24'});
                mapBaro.el.text(x-3,height-heightOff-8,''+((t-timeStart) / 3600000)+'h').attr({font:'12px arial'}).attr({fill:'#DD6E24'}).attr({'text-anchor': 'end'});
            }
            else if ((t-timeStart) % 1800000 == 0) {
                line.attr({stroke:'#DDA681'});
            }
            else {
                line.attr({stroke:'#DDCFC6'});
            }
        }

        let px=0+widthOff;
        let py=Math.round(height-(d4.alt[a]-yoffset)*yscale)+heightOff;

        let bPath='M'+px+' '+py+'L';

        for (let i=a+1 ; i<=b ; i++) {
            let x=Math.round((d4.time[i]-xoffset)*xscale)+widthOff;
            let y=Math.round(height-(d4.alt[i]-yoffset)*yscale)+heightOff;
            if (px!=x || py!=y) {
                bPath+=x+' '+y+' ';
                px=x;
                py=y;
            }
        }
        mapBaro.el.path(bPath).attr({stroke:'blue'});


        mapBaro.cursorPos=widthOff;
        mapBaro.cursor=mapBaro.el.path('M'+widthOff+' '+heightOff+'L'+widthOff+' '+(height-heightOff)).attr({stroke:'#ff00ff'});


        if (mapBaro.flightContext.ccmap && d4.time[mapBaro.flightContext.ccmap.fixPointer]-xoffset!=0) {
            mapBaro.cursorPos=Math.round((d4.time[mapBaro.flightContext.ccmap.fixPointer]-xoffset)*xscale)+widthOff;
            mapBaro.cursor.transform('t'+mapBaro.cursorPos+',0');
        }

        if (this.flightContext.ccmap!==undefined && this.flightContext.ccmap.task!==undefined) this.addTask(this.flightContext.ccmap.task,d4);

    }

    projectTime(milis) {
        return Math.round((milis-this.xoffset)*this.xscale)+this.widthOff;
    }
    projectHeight(h) {
        return Math.round(this.container.clientHeight-(h-this.yoffset)*this.yscale)+this.heightOff;
    }
    addTask(task,d4) {
        if (this.heightOff===undefined) return;
        if (this.taskLines!==undefined) {
            for (let i=0; i<this.taskLines.length;i++) {
                this.taskLines[i].remove();
            }
        }

        this.taskLines=[];

        if (task.clazz==3 && task.task.data.f) {
            let fixes=task.task.data.f;
            for (let i=0; i<fixes.length; i++) {
                if (fixes[i]===undefined) continue;
                let x=this.projectTime(d4.time[fixes[i].brec]);
                this.taskLines[i]=this.el.path('M'+x+' '+this.heightOff+'L'+x+' '+(this.container.clientHeight-this.heightOff));
                this.taskLines[i].attr({stroke: '#67e9dc'});
            }
        }
        else if (task.task.fix) {
            for (let i=0; i<task.task.fix.length; i++) {
                if (task.task.fix[i]===undefined) continue;
                let x=this.projectTime(d4.time[task.task.fix[i]]);
                this.taskLines[i]=this.el.path('M'+x+' '+this.heightOff+'L'+x+' '+(this.container.clientHeight-this.heightOff));
                this.taskLines[i].attr({stroke: '#67e9dc'});
            }
        }
    }
}


export default Barogram

