import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '../config'
import { Buffer } from "buffer";
import {login, setToken, logout} from "./sessionSlice";



export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.serverUrl,
        prepareHeaders: (headers, { getState }) => {
            if (!headers.get('authorization')) {
                const token = getState().session.token
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    endpoints: builder => ({
        authenticate: builder.mutation({
            query:(data) => ({
                url:'/token',
                method:'POST',
                headers:{
                    Authorization:'Basic '+Buffer.from(`${data.username}:${data.password}`).toString('base64')
                }
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setToken(data))
                    await dispatch(apiSlice.endpoints.getPrincipal.initiate(null));
                } catch (error) {
                    console.log('**** Error on authentication')
                    console.log(error)
                }
            },
        }),
        /**called to refresh JWT token and user data when we have a token*/
        refreshAuth: builder.mutation({
            query:({token}) => ({
                url:'/token',
                method:'POST',
                headers:{
                    Authorization:'Bearer '+token
                }
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setToken(data))
                    await dispatch(apiSlice.endpoints.getPrincipal.initiate(null));
                } catch (error) {
                    console.log('**** Token refresh Auth error')
                    console.log(error)
                    if (error.error.status === 401) {
                        //the session token is stale so we are logging out
                        //TODO: change this when we have a remember me token so that we can directly reauthenticate
                        dispatch(logout())
                        console.log('**** logging out')
                    }
                }
            },
        }),
        getPrincipal: builder.mutation({
            query : () => '/get/principal',
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(login(data))
                } catch (error) {
                    console.log('**** Error on getPrincipal')
                    console.log(error)
                }
            },
        }),
        getRegions: builder.query({
            query: () => '/get/region'
        }),
        getFlights: builder.query({
            query: ({pilotId,page}) => `/get/flight?pilotId=${pilotId}&page=${page}`
        }),
        getFlight: builder.query({
            query: (id) => `/get/flight?id=${id}`
        }),
        getPerformance: builder.query({
            query: (id) => `/get/performance?id=${id}`
        }),
        getFlightPerformances: builder.query({
            query: (flightId) => `/get/performance?flightId=${flightId}`
        }),
        getFlightMetadata: builder.query({
            query: (flight) => `/load/flightMetadata?flightId=${flight.id}&userId=${flight.userId}`
        }),
        getGMap: builder.query({
            query: (flightId) => ({
                url: `/load/gmap?flightId=${flightId}`,
                responseHandler: (response) => response.text(),
            })
        }),
        get4D: builder.query({
            query: (flightId) => `/load/4d?flightId=${flightId}`
        }),
        get5D: builder.query({
            query: (flightId) => `/load/5d?flightId=${flightId}`
        }),
        getEvents: builder.query({
            query: () => `/public/get/events`
        }),
        getEvent: builder.query({
            query: (id) => `/public/get/event?id=${id}`
        }),
        getIgcFileMeta: builder.query({
            query: (id) => `/get/igcFile?id=${id}`
        }),
        getIgcFileContent: builder.query({
            query: (id) => ({
                url: `/load/igcFile?id=${id}`,
                responseHandler: (response) => response.text(),
            }),
        }),

    })
})

export const {
    useGetRegionsQuery,
    useGetFlightsQuery,
    useGetFlightQuery,
    useGetPerformanceQuery,
    useGetFlightPerformancesQuery,
    useGetFlightMetadataQuery,
    useGetGMapQuery,
    useGet4DQuery,
    useGet5DQuery,
    useAuthenticateMutation,
    useRefreshAuthMutation,
    useGetPrincipalMutation,
    useGetEventsQuery,
    useGetEventQuery,
    useGetIgcFileMetaQuery,
    useGetIgcFileContentQuery,
} = apiSlice