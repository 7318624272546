import React, {useContext, useRef} from 'react';
import {useMap} from "react-leaflet";

const CCMap = ({flight,children}) => {
    const map = useMap()

    if (map) {
        //TODO: create CCMap object
        const ccmap={};
        map.ccmap=ccmap
    }

    return (
        <>
            {
                map &&
                children
            }
        </>
    )

}

export default CCMap