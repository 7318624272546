import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import Map from "../../components/Map";
import MapControls from "../../components/Map/MapControls";
import Authenticated from "../../components/Authenticated";

const Analysis = () => {
    return (
        <Authenticated>
            <Grid2 container>
                <Grid2 xs={12}>
                    <Map lat={38} lon={23} zoom={12} height={'80vh'}>
                        <MapControls/>
                    </Map>
                </Grid2>
            </Grid2>
        </Authenticated>
    );
}

export default Analysis;