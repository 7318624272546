import React from 'react'
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export default function Terms() {

    return (
        <Container sx={{textAlign:'left'}} className={'paragraph'}>
            <Typography variant={'h5'} component={'h2'}>Terms of Use</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>The following terms and conditions of use of the website crosscountry.aero (hereafter "Terms of use") regulate the use of this website. This service (hereafter "Site") is provided by Crosscountry Aero Ltd (hereafter the Provider), registered in England and Whales with company number 08557428, registered office address: 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom.</p>
                <p>The Provider makes this service available for private use by glider pilots (hereafter Participants) for their convenience as a flight evaluation, analysis and flight-related content publishing tool. In addition, a pilot ranking service and other associated services are provided in form of a competition (hereafter Competitions). The service is also provided to pilots and the general public alike (hereafter Users) for the purpose of viewing this information. Crosscountry.aero (hereafter the Site) may also provide additional services related to the subject of soaring, which are covered by these terms.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Participants</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>Participants are required to register with the Site by providing their true identity and current contact email address. For sporting reasons, it is essential that accurate and complete personal and sport affiliation information is provided.</p>
                <p>Participants may submit flight data in the form of flight logs (IGC files) and associated data to the Site. When submitting flight data, the Participant declares that this data is derived by his/her flight activities while Pilot in Command of a glider (including motorglider, ultralight glider or ultralight motorglider), that such flight activities were conducted according to the legal requirements and standards of the nation or nations where the flight was conducted, as well as with the sporting regulations applicable, that all statements and declarations associated with this data are truthful, and that the data logs (IGC files) submitted have not been altered in any way (other than allowed by the FAI sporting rules).</p>

                <p>By submitting flight data, the Participant grants the Provider the irrevocable, transferable, non-exclusive right to store, process and publish this flight data, to create, publish, and otherwise use derived works from this information, including to transfer this flight data or derived works to 3rd parties. This right is provided by the Participant to the Provider free of charge and with the agreement that no further claim, financial or legal redress shall be made by the Participant to the Provider arising from the use of this data by the Provider, or 3rd parties associated with this service.</p>

                <p>Participants may also submit content related to their flight activities, including photographic images, text, links to external content, including video and other associated content. This content must be related to personal gliding activities and must conform to accepted norms of decency and sporting etiquette. Indecent, offensive, discriminatory material will not be tolerated. Use of the content publishing service for commercial reasons or financial gain without the express authorization of the Provider will not be permitted. The Provider reserves the right to alter or remove content that is not in agreement with these terms.</p>

                <p>Participants may also submit other data associated with gliding activity, including airfield information, turn points, tasks etc, provided this is done in good faith and according to sporting and airmanship norms.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Competitions</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>The Site provides a Pilot Ranking according to flight performances achieved, in the form of a competition. The terms governing this competition are specified by the Pilot Ranking Rules. From time to time, the Site may offer other forms of competition, in which case the appropriate rules will be published.</p>
                <p>When submitting a flight, the Participant explicitly indicates that he/she agrees to the flight being evaluated for participation in the Pilot Ranking or any other competition associated with the Site for which it may be eligible. If the Participant does not want the specific flight to be evaluated against a competition, because he/she has deemed that the flight data or associated information is erroneous, inaccurate or does not conform to sporting regulations, the Participant can notify the Provider to exclude this flight from competition evaluation.</p>

                <p>Participation in Competitions is honor based, requiring the participant to truthfully state their flight performances and associated data. The Provider reserves the right to further investigate the accuracy of submissions at any time and to take appropriate action if it is deemed that a flight performance has been awarded under doubtful circumstances. Cheating will not be tolerated and will lead to termination of service provision by the Provider. Participants may raise objections to published sporting performances by contacting the Provider. The Provider is the final arbiter in all sporting matters relevant to Competitions.</p>

                <p>The Provider reserves the right to change the rules governing Competitions at any time. Such rule changes will be notified by publication of new rules on the Site. No claim may arise from Participants in regards to rule changes.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Users</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>Users may use the services made available by the Site for the purposes intended, which include the evaluation and analysis of flights, the sharing of flight-related experiences and information, the discussion of gliding-related issues, the promotion of the sport of gliding as an activity and the publication and promotion of pilot achievements and experiences. The Provider makes available the services of the Site with the explicit understanding that they are made available for private use and that they are not suitable for a flight operation environment. Any participation of Users or use of information or services of the Site should occur in a spirit which helps promote the sport of gliding.</p>
                <p>Users are granted the non-transferable right to view, download and locally store flight data in the form of IGC files available on the site provided it is for their private use only. Commercial use of this data and transfer to third parties, including posting to flight evaluation or competition sites is expressly prohibited. This right is provided according to the terms of fare use of resources. Consistent abuse of the fare use of resources terms and activities such as data mining, or systematic download of great amounts of flight logs may lead to the Provider terminating provision of services to the User. Breach of the terms of use of this Site constitutes a revocation of all rights granted, past and present, from the Provider to the User to use flight data.</p>

                <p>Use of the Site and its associated services is only allowed according to the terms specified herein. Any unauthorized use which does not agree with these terms constitutes a breach of agreement and allows the Provider to discontinue further use of the services by such User. The Provider reserves the rights to seek appropriate further action, as specified by law, in cases of breach of terms of use, were such action is warranted.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Copyright</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>When submitting content, including photographic images, text or other associated media, on the site, the Participant grants the non-exclusive, non-transferable right to the Provider to store and publish this media on the site or in associated 3rd party services, through syndication or sharing through social media. The Participant must ensure that he/she has the rights to the content or has received authorization to publish the content on the Site and grant the associated rights to the Provider.</p>
                <p>The copyright for the content remains with the original creator and the Participant may choose to revoke the rights granted to the Provider by removing the content from the site. In any case, the Provider always retains the right to store removed content for technical reasons, such as for backup of application data.</p>

                <p>Holders of copyrighted works appearing on the Site without their permission should immediately contact the Provider for the removal of their work. Users who post copyrighted works without the right holder's permission shall be solely liable to claims made by owners of the rights.</p>

                <p>All derived data presented on the site, including derived works from flight data is copyright of crosscountry.aero and all rights are reserved.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Fare use of resources</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>The Site services are provided to the gliding community by the Provider with the understanding that individual Users will make appropriate use of these services for their own private purposes and for the intentions specified herein. Excessive use of resources by an individual user, beyond the expected norms for intended use constitutes a threat to the availability of these services to the wider community and may in some cases impact an inordinate cost to the Provider for the operation of the Site.</p>
                <p>Such excessive or inappropriate use constitutes a breach the terms. The Provider may take appropriate measures to limit such abuse of resources including, but not limited to, restricting access to specific services or terminating provision of services to Users who abuse resources.</p>

                <p>The Provider also reserves the right to alter the way in which resource-intensive services are provided to ensure that these services continue to be available to the community while resource abuse is minimized.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Research</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>We believe that research has an important role to play in better understanding our sport and in helping its further development. If you are interested in conducting research, please do not be tempted to download IGC files from the site directly as this would have significant cost implications to us and would also impact access to the service by other users. It would also constitute a breach of the terms of use.</p>
                <p>Individuals interesting in conducting research with the flight data available on the Site are encouraged to contact us. We would like to work with you to ensure the best level of cooperation between researchers, access to existing studies and publication of results to the wider community.</p>
            </Typography>
            <Typography variant={'h6'} component={'h3'}>Liability disclaimer</Typography>
            <Typography variant={'body1'} component={'div'}>
                <p>This service is provided "as is" and no warrantee is provided pursuant to its use. No claim is made as to the accuracy of data presented on the Site or towards the availability of services on the Site. The Provider reserves the right to modify the services provided, their availability or the terms of their use.</p>
                <p>By using the services available on the site, User explicitly agrees that the Provider is not liable for any claims for monetary compensation or other legal redress by the User arising from the use of this service or from actions or events associated this its use.</p>

                <p>Competitors participate in Competitions at their own risk. No claim shall be made against the Provider by Participants or persons associated or representing Participants in relation to participation in Competitions, or any other use of the site.</p>
            </Typography>
            <Typography variant={'body1'} component={'div'}><p>&nbsp;</p></Typography>
        </Container>
    )

}