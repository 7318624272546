import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import Map from "../../components/Map";
import MapControls from "../../components/Map/MapControls";
import MapFlightRenderer from "../../components/Map/MapFlightRenderer";
import MapTaskRenderer from "../../components/Map/MapTaskRenderer";
import Authenticated from "../../components/Authenticated";

const Planning = () => {
    return (
        <Authenticated>
            <Grid2 container>
                <Grid2 xs={12}>
                    <Map lat={38} lon={23} zoom={12} height={'calc(100vh - 68px)'}>
                        <MapControls/>
                    </Map>
                </Grid2>
            </Grid2>
        </Authenticated>
    );
}

export default Planning;