import React from 'react';
import Container from "@mui/material/Container";
import {Outlet} from "react-router-dom";


const Competition = () => {


    return (
        <Container>
            <Outlet/>
        </Container>
    );
}

export default Competition;