import React from 'react';
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useGetFlightsQuery} from "../slices/apiSlice";
import Authenticated from "../components/Authenticated";
import FlightList from "../components/FlightList";
import {Skeleton} from "@mui/material";
import Container from "@mui/material/Container";

const Flights = ({ targetPilotId }) => {
    const [searchParams] = useSearchParams()
    const session = useSelector(state => state.session)

    const pilotId = targetPilotId ?? session.pilot?.id
    const skip = pilotId === undefined

    //make up for the fact that pages are zero-based in the query but not in the index
    let page = searchParams.get('page') ?? 0

    const {
        data: flights,
        isSuccess,
    } = useGetFlightsQuery({pilotId,page},{skip})


    return (
        <Authenticated>
            <Container maxWidth={'lg'} disableGutters>
                {
                    isSuccess ?
                        <FlightList data={flights} pilotId={pilotId} isOwn={false}/>
                        :
                        <>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                        </>
                }
            </Container>
        </Authenticated>
    )
}

export default Flights;