import React, { Component } from 'react';

//https://www.npmjs.com/package/react-country-region-selector
// note that you can also export the source data via CountryRegionData. It's in a deliberately concise format to
// keep file size down
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';
import { useNavigate } from "react-router-dom";


function CountrySelector (props) {

    const navigate = useNavigate();

    return (
        <div>
            <CountryDropdown
                value={props.selectedCountry}
                valueType="short"
                whitelist={props.countries}
                onChange={(val) => selectCountry(val)} />
        </div>
    );

    function selectCountry (val) {
        navigate(`?country=${val}`)
    }
}

export default CountrySelector;