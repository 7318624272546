import React from 'react';
import {useParams} from 'react-router-dom'
import {useGetFlightQuery} from "../slices/apiSlice";
import FlightView from "../components/Flight/FlightView";
import FlightContext from "../components/Flight/FlightContext"
import Authenticated from "../components/Authenticated";
import Container from "@mui/material/Container";


const Flight = (props) => {

    const {flightId} = useParams()


    return (
        <Authenticated>
            <Container>
               <FlightContext.Provider value={
                   {
                       flightId:flightId,
                   }
               }>
                    <FlightView flightId={flightId}/>
               </FlightContext.Provider>
            </Container>
        </Authenticated>
    );
}

export default Flight