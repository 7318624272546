import React from 'react';
import {useGetFlightsQuery} from "../../slices/apiSlice";
import FlightList from "../../components/FlightList";
import {useSearchParams} from "react-router-dom";

const Flights = () => {

    const [searchParams] = useSearchParams()

    //TODO: change this as it defaults to pilot with id 1 if no pilot id is present in the url query
    const pilotId = searchParams.get('pilotId') ?? 1
    //make up for the fact that pages are zero-based in the query but not in the index
    let page = searchParams.get('page') ?? 0

    const {
        data: flights,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetFlightsQuery({pilotId,page})

    return (
        <>
            <div className='App'>Flights</div>
            {isSuccess && <FlightList data={flights} pilotId={pilotId}/>}
        </>
    );
}

export default Flights;