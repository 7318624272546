import "@fontsource/open-sans";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette:{
        primary: {
            main: '#1976d2',
            dark: '#214568',
        }
    },
    typography: {
        fontFamily: ['"Open Sans"', 'Helvetica', 'Arial'].join(','),
        h3: {
            fontSize: '1.3rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ["Open Sans"],
            },
        }
    }
})

export default theme;