import React from 'react';
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {Chip} from "@mui/material";
import {getPerformanceDescription} from "./PerformanceInfo";
import {useSelector} from "react-redux";

const PerformanceSelector = ({performances, selectedPerformance, handleSelect}) => {
    const performanceTypes =  useSelector(state => state.globals.messages.performanceTypes)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClick = (value) => {
        setAnchorEl(null);
        handleSelect(value)
    };

    return (
        <Box>
            <Chip label={`${performances.length} performances`} onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {handleMenuClick()}}
            >
                <MenuItem key={selectedPerformance.id} onClick={() => {handleMenuClick(selectedPerformance.id)}}>
                    {performanceTypes[selectedPerformance.type].name}
                    &nbsp;
                    {getPerformanceDescription(selectedPerformance)}
                </MenuItem>
                {
                    performances.map(p => {
                        return (p.id !== selectedPerformance.id) ?
                             <MenuItem key={p.id} onClick={() => {handleMenuClick(p.id)}}>
                                {performanceTypes[p.type].name}
                                 &nbsp;
                                {getPerformanceDescription(p)}
                             </MenuItem>
                            :
                            undefined
                    })
                }
            </Menu>
        </Box>
    )
}

export default PerformanceSelector;