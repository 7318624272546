import React from 'react';
import Authenticated from "../../components/Authenticated";

const Club = () => {
    return (
        <Authenticated>
            <div className='App'>Club</div>
        </Authenticated>
    );
}

export default Club;